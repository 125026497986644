import { useState } from "react";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

import { useOnDemandNationalCouponPrograms } from "@features/programs";

import { SECONDARY_ORDER_WINDOWS } from "../../constants/permissions";
import useActiveOrderWindows from "../../hooks/useActiveOrderWindows";
import { getLinkProps } from "../../utils/linkProps";
import ImageWrapper from "../Utility/ImageWrapper";

const planningToolRoles = ["purchaser", "select-purchaser", "super", "field2"];

const FieldDash = ({ classes, name, InfoPopover }) => {
  const activeOrderWindows = useActiveOrderWindows();

  const hasActiveNational = activeOrderWindows.some(
    (ocm) => ocm.orderWindowType === "national"
  );
  const activeSecondary = activeOrderWindows.find(
    (ocm) => ocm.orderWindowType === "secondary"
  );

  const onDemandNationalCouponPrograms = useOnDemandNationalCouponPrograms();

  const [anchorEl, setAnchorEl] = useState(null);

  const { role } = useSelector((state) => state.user);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const planningToolOpen = anchorEl
    ? anchorEl.id === "planning-tool-parent"
    : false;
  const preOrderOpen = anchorEl ? anchorEl.id === "pre-order-parent" : false;
  const specialPreOrderOpen = anchorEl
    ? anchorEl.id === "special-pre-order-parent"
    : false;
  const inventoryOpen = anchorEl ? anchorEl.id === "inventory-parent" : false;
  const onDemandOpen = anchorEl ? anchorEl.id === "on-demand-parent" : false;
  const historyOpen = anchorEl ? anchorEl.id === "history-parent" : false;
  const catalogCurrentOpen = anchorEl
    ? anchorEl.id === "catalog-current-parent"
    : false;
  const catalogArchiveOpen = anchorEl
    ? anchorEl.id === "catalog-archive-parent"
    : false;
  const itemRulesOpen = anchorEl ? anchorEl.id === "item-rules-parent" : false;
  const budgetsOpen = anchorEl ? anchorEl.id === "budgets-parent" : false;
  const wrapReportOpen = anchorEl
    ? anchorEl.id === "reports-landing-parent"
    : false;
  const knowledgeBaseOpen = anchorEl
    ? anchorEl.id === "knowledge-base-parent"
    : false;

  const cards = [
    {
      titleText: "RTA Planning Tool",
      ariaOwnsState: planningToolOpen,
      ariaOwnsText: "planning-tool",
      id: "planning-tool-parent",
      link: "/planning/dashboard",
      info: "View Planning Tool",
      icon: "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
    },
    {
      titleText: "Monthly Pre Order",
      ariaOwnsState: preOrderOpen,
      ariaOwnsText: "pre-order",
      id: "pre-order-parent",
      link: "/programs",
      info: hasActiveNational
        ? "Place your Monthly Pre Order. Orders are placed in groups based on programs and addresses that are availiable within your assigned Regions and Key Accounts"
        : "There are currently no active pre order programs",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Q1_Ordering_tg2ryl.png",
    },
    ...(activeSecondary && SECONDARY_ORDER_WINDOWS.includes(role)
      ? [
          {
            titleText: activeSecondary.orderWindowName,
            ariaOwnsState: specialPreOrderOpen,
            ariaOwnsText: "special pre-order",
            id: "special-pre-order-parent",
            link: "/secondary-order-windows",
            info: "Special Order Window",
            icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Q1_Ordering_tg2ryl.png",
          },
        ]
      : []),
    {
      titleText: (
        <>
          Made to Order <br />
          (On Demand)
        </>
      ),
      ariaOwnsState: onDemandOpen,
      ariaOwnsText: "on-demand",
      id: "on-demand-parent",
      link: "/orders/items/onDemand",
      info: "Place orders for items that will need to be produced for your order",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Place_On_Demand_Order_zoymv2.png",
    },
    {
      titleText: (
        <>
          Ready to Ship <br />
          (Inventory)
        </>
      ),
      ariaOwnsState: inventoryOpen,
      ariaOwnsText: "inventory",
      id: "inventory-parent",
      link: "/orders/items/inventory",
      info: "Place orders for items that are currently available in our inventory",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Place_In_Stock_Order_skvxpj.png",
    },
    ...(onDemandNationalCouponPrograms.length > 0
      ? [
          {
            titleText: "Coupons",
            ariaOwnsState: onDemandOpen,
            ariaOwnsText: "on-demand",
            id: "on-demand-parent",
            link: `/orders/items/coupons`,
            info: "Special Coupon Program",
            icon: "https://res.cloudinary.com/brandhub/image/upload/c_crop,w_300,h_220/v1617273999/prod/Icons/SELECT_Icons_Order_Management_m5zx9c.png",
          },
        ]
      : []),
    {
      titleText: "Order History",
      ariaOwnsState: historyOpen,
      ariaOwnsText: "order-history",
      id: "history-parent",
      link: "/orders/history/group/byItem",
      info: "View order history grouped by item or order & get tracking information",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Order_History_jvvgmh.png",
    },
    {
      titleText: "Order Window Summary Report",
      ariaOwnsState: wrapReportOpen,
      ariaOwnsText: "reports-order-window-summary-presentation",
      id: "reports-order-window-summary-presentation-parent",
      link: "/reports/order-window-summary-presentation",
      info: "View order window summary report",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
    },
    {
      titleText: "Item Catalog: Current",
      ariaOwnsState: catalogCurrentOpen,
      ariaOwnsText: "catalog-current",
      id: "catalog-current-parent",
      link: "/items/all",
      info: "View catalog of and details about currently available items",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Current_ykkrrc.png",
    },
    {
      titleText: "Item Catalog: Archive",
      ariaOwnsState: catalogArchiveOpen,
      ariaOwnsText: "catalog-archive",
      id: "catalog-archive-parent",
      link: "/items/archive",
      info: "View archive of and details about items no longer available",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Archive_wgetjy.png",
    },
    {
      titleText: "Reports",
      ariaOwnsState: wrapReportOpen,
      ariaOwnsText: "reports-landing",
      id: "reports-landing-parent",
      link: "/reports/landing",
      info: "View all reports",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
    },
    {
      titleText: "Item Rules",
      ariaOwnsState: itemRulesOpen,
      ariaOwnsText: "item-rules",
      id: "item-rules-parent",
      link: "/compliance/items",
      info: "View details about regional item compliance",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Rules_r2iepr.png",
    },
    {
      titleText: "Budgets",
      ariaOwnsState: budgetsOpen,
      ariaOwnsText: "budgets",
      id: "budgets-parents",
      link: "/budgets",
      info: "View budgets",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Rules_r2iepr.png",
    },
    {
      titleText: "Knowledge Base",
      ariaOwnsState: knowledgeBaseOpen,
      ariaOwnsText: "knowledge-base",
      id: "knowledge-base-parent",
      link: "https://help.readytoactivate.com/",
      info: "Access the knowledge base for more information about RTA's functionality",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Current_ykkrrc.png",
    },
  ];

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      style={{ width: "80%" }}
    >
      <div style={{ paddingLeft: "24px", width: "100%", textAlign: "left" }}>
        <Typography className={classes.titleText}>
          {`Welcome back ${name}!`}
        </Typography>
      </div>
      {cards.map(
        (data, index) =>
          (index > 0 || planningToolRoles.includes(role)) && (
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              {...getLinkProps(data.link)}
              key={index}
            >
              <div className={classes.dashboardGridItem}>
                <Paper className={classes.dashPaper} elevation={5}>
                  <div
                    id={data.id}
                    className={classes.innerPaper}
                    aria-owns={
                      data.ariaOwnsState ? data.ariaOwnsText : undefined
                    }
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    onClick={data.onClick}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "30%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography className={classes.titleText}>
                        {data.titleText}
                      </Typography>
                    </div>
                    <Divider className={classes.divider} />
                    <ImageWrapper
                      imgUrl={data.icon}
                      alt={data.titleText}
                      imgClass={classes.icon}
                      id={`${data.id}-image`}
                      handleClick={null}
                    />
                    {/* <img className={classes.icon} src={data.icon} /> */}
                  </div>
                </Paper>
              </div>
              <InfoPopover
                id={data.id}
                info={data.info}
                classes={classes}
                open={data.ariaOwnsState}
                anchorEl={anchorEl}
                handlePopoverClose={handlePopoverClose}
              />
            </Grid>
          )
      )}
    </Grid>
  );
};

FieldDash.propTypes = {
  classes: PropTypes.object.isRequired,
  InfoPopover: PropTypes.func.isRequired,
  currentMonth: PropTypes.number,
};

export default FieldDash;
