/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useMemo, useState } from "react";
import { ControllerProps, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { FormControlLabel, Radio } from "@mui/material";

import _ from "lodash";
import {
  ControlledRadioGroup,
  DateInput,
  SelectInput,
  TextInput,
} from "src/components/Forms/ControlledInputs";
import formatInMarketMonthOptions from "src/components/PlanningTool/formatInMarketMonthOptions";
import { StyledButton } from "src/components/StyledComponents";
import { useFutureInMarketMonths } from "src/hooks/planningToolHooks";

import ControlledBrandAutocomplete from "@utils/forms/ControlledBrandAutocomplete";

const orderTypes = [
  { id: "pre-order", name: "Pre Order" },
  { id: "on-demand", name: "On Demand" },
  { id: "from-inventory", name: "Inventory" },
];
const channels = [
  { id: "retail", name: "Retail" },
  { id: "on_premise", name: "On Premise" },
];
const programFormValues = {
  programTypeId: "1",
  brandAssignmentLevel: "program",
  brands: [] as string[],
  name: "",
  orderType: "pre-order",
  channel: "",
  orderCalendarMonthId: "",
  primaryBrand: "",
  startDate: null as null | Date,
  endDate: null as null | Date,
};

const CreateProgramForm = ({
  defaultValues,
  onSubmit,
  disabledFields = [],
  submitButtonText = "Submit",
  programTypeIds = [],
}: {
  defaultValues: Partial<typeof programFormValues>;
  onSubmit: (data: Partial<typeof programFormValues>) => void;
  disabledFields?: string[];
  submitButtonText: string;
  programTypeIds: string[];
}) => {
  const [loading, setLoading] = useState(false);
  const programTypes = useSelector(
    (state: any) => state.programTypes.programTypes
  );

  const futureOcms = useFutureInMarketMonths();
  const ocmOptions = formatInMarketMonthOptions(futureOcms);

  const { control, handleSubmit, watch, setValue } = useForm<
    typeof programFormValues
  >({
    defaultValues: {
      ...programFormValues,
      ...defaultValues,
      programTypeId: defaultValues?.programTypeId ?? programTypeIds[0],
    },
  });
  const [programTypeId, orderType, brandAssignmentLevel, startDate] = watch([
    "programTypeId",
    "orderType",
    "brandAssignmentLevel",
    "startDate",
  ]);

  const orderTypeOptions = useMemo(() => {
    // National Coupon
    if (programTypeId === "4") {
      return orderTypes.filter(({ id }) => id !== "from-inventory");
    }
    return orderTypes;
  }, [programTypeId]);

  const reg = (name: string, rules?: ControllerProps["rules"]) => ({
    name,
    control,
    rules,
    disabled: disabledFields.includes(name),
  });

  const handleSubmitWithLoading = async (data: typeof programFormValues) => {
    try {
      setLoading(true);
      // Conditionally add brands or inMarketDates based on the orderType and brandAssignmentLevel
      const cleanedData = {
        ..._.omit(data, [
          "brands",
          "startDate",
          "endDate",
          "orderCalendarMonthId",
        ]),
        ...(data.brandAssignmentLevel === "program" && { brands: data.brands }),
        ...(data.orderType === "pre-order"
          ? { orderCalendarMonthId: data.orderCalendarMonthId }
          : {
              startDate: data.startDate,
              endDate: data.endDate,
            }),
      };
      onSubmit(cleanedData);
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitWithLoading)}
      tw="flex flex-col gap-4"
    >
      <em tw="text-sm text-neutral-600">* Indicates required fields</em>
      <SelectInput
        options={programTypes.filter(({ id }) => programTypeIds.includes(id))}
        {...reg("programTypeId")}
        label="Program Type"
        disabled={programTypeIds.length === 1}
        onChange={(e) => {
          if (e.target.value === "4") {
            setValue("orderType", "on-demand");
          }
        }}
      />
      <ControlledRadioGroup
        {...reg("brandAssignmentLevel")}
        label="Brand Assignment Type"
        row
      >
        <FormControlLabel
          value="program"
          control={<Radio />}
          label="Single Brand / Portfolio"
        />
        <FormControlLabel
          value="item"
          control={<Radio />}
          label="Multi-Brand"
        />
      </ControlledRadioGroup>
      {brandAssignmentLevel === "program" && (
        <ControlledBrandAutocomplete
          multiple
          {...reg("brands", { required: true })}
        />
      )}
      <TextInput label="Program Name *" {...reg("name", { required: true })} />
      <SelectInput
        options={orderTypeOptions}
        {...reg("orderType", { required: true })}
        label="Order Type"
      />
      <SelectInput
        options={channels}
        {...reg("channel", { required: true })}
        label="Trade Class *"
      />

      {orderType === "pre-order" ? (
        <SelectInput
          options={ocmOptions}
          {...reg("orderCalendarMonthId", { required: true })}
          label="In Market Month *"
        />
      ) : (
        <div tw="flex gap-3">
          <DateInput
            label="In Market Start Date *"
            {...reg("startDate", { required: true })}
          />
          <DateInput
            label="In Market End Date *"
            {...reg("endDate", { required: true })}
            minDate={startDate ?? undefined}
          />
        </div>
      )}
      <StyledButton cta type="submit" loading={loading}>
        {submitButtonText}
      </StyledButton>
      {loading && (
        <div tw="text-sm text-neutral-500 text-center mt-2">
          This may take a minute. Do not refresh the page.
        </div>
      )}
    </form>
  );
};

export default CreateProgramForm;
