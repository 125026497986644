import fp from "lodash/fp";

import { VariantCategoryName } from "@models/VariantOption";
import asyncPool from "@utils/asyncPool";

import { axiosPost, axiosPut } from "../../../api/axiosCalls";
import { camelCaseKeys } from "../../../utility/utilityFunctions";
import {
  resetStepperValue,
  setIsStepper,
  updateStepperValue,
} from "../globalLoadSlice";

const n = (v) => (v ? +v : null);

export const mapImage = fp.flow(
  camelCaseKeys,
  fp.pick(["id", "type", "position", "cloudinaryId", "pdfPageCount"])
);

const mapImages = (images) => images.map(mapImage);

export const mapItem = fp.flow(
  camelCaseKeys,
  fp.pick([
    "id",
    "images",
    "itemType",
    "itemSubType",
    "comment",
    "itemNumber",
    "itemOrderType", // ?
    "itemStatus",
    "leadTimeInDays",
    "name",
    "poInMarketDate",
    "qtyPerPack",
    "removeFromCatalogDate",
    "specification",
    "supplier",
    "allowSupplierUpdate",
    "type",
    "variants",
    "designType",
    "creativeInstructions",
    "tieredPricingDescription",
    "programs",
    "mostRecentEstimatedCost",
    "mostRecentMoq",
    "otherNotes",
    "includeBeacon",
    "artReferenceNumber",
    "standardSpecificationCode",
    "itemTypePosType",
    "orderableStartDate",
    "orderableEndDate",
    "brands",

    // bools
    "isAccolade",
    "isActive",
    "isInventory",
    "isMetalOrWood",
    "isSweepstakes",
    "sendToContentHub",
    "rtaDeployment",
    "becomesOnDemand",

    // Coupon
    "isCoupon",
    "isCustomCoupon",
    "isDigitalCoupon",
    "clonedFromId",
    "clones",
    "couponBarcodeId",
    "couponCopyDone",
    "couponEstimatedRedemption",
    "couponExpirationDate",
    "couponFaceValue",
    "couponIssueDate",
    "couponOfferDescription",
    "couponPurchaseRequirementCode",
    "couponTotalValueMir",
    "couponType",
    "couponUuid",
    "couponOrderStartDate",
    "couponOrderEndDate",
  ]),
  (obj) => ({
    ...obj,
    variants: obj.variants.map(camelCaseKeys),
    variantOptionsIds: obj.variants
      .filter((v) => v["is-active"])
      .map((v) => v["selected-variant-options"][0]?.id)
      .filter(Boolean),
    itemTypeId: obj.itemType.id,
    itemSubTypeId: obj.itemSubType?.id || null,
    couponTypeId: obj.couponType?.id,
    couponId: obj.coupon?.id || null,
    clones:
      obj.clones?.map((clone) => clone["item-program-id"])?.filter(Boolean) ||
      [],
    clonedFromId: obj.clonedFromId?.toString() || null,
    itemId: obj.id,
    couponEstimatedRedemption: n(obj.couponEstimatedRedemption),
    couponTotalValueMir: n(obj.couponTotalValueMir),
    images: mapImages(obj.images),
    programNames: obj.programs?.map((p) => p.name) || [],
    supplierId: obj.supplier?.id,
    itemBrands: obj.brands.map(fp.pick(["id", "name"])),
    specification: obj.specification ?? {},
  }),
  fp.omit([
    "brands",
    "couponType",
    "itemSubType",
    "itemType",
    "programs",
    "supplier",
  ])
);
export const mapItemProgram = fp.flow(
  camelCaseKeys,
  // inspect("api"),
  fp.pick([
    "id",
    "estimatedQty",
    "estimatedCost",
    "moq",
    "orderCalendarMonth",
    "orderType",
    "item",
    "program",
    "isReRun",
    "complexityScore",
    "mandatoryCode",
    "beaconCost",
    "brands",
    "brandName",
  ]),
  (obj) => fp.merge(mapItem(obj.item), obj),
  (obj) => ({
    ...obj,
    programId: obj.program.id,
    programName: obj.program.name,
    orderCalendarMonthId: obj.orderCalendarMonth?.id || null,
    orderCalendarMonth: obj.orderCalendarMonth
      ? camelCaseKeys(obj.orderCalendarMonth)
      : null,
    itemProgramType: obj.isCustomCoupon
      ? "templatedCoupon"
      : obj.isCoupon
      ? "coupon"
      : obj.isReRun
      ? "rerun"
      : "item",
    // brands are only available on "item" brandLevelAssignment programs (aka multi-brand programs)
    brands: obj.brands.map(fp.pick(["id", "name"])),
  }),
  fp.omit(["item", "program"])
);
export const mapItemPrograms = (ip) => fp.reverse(ip).map(mapItemProgram);

/**
 * Helpers
 */

const variantBody = ({ itemId, isActive, variantOptionId }) => ({
  data: {
    attributes: {
      ...(itemId && { "item-id": itemId }),
      "is-active": isActive ?? true,
    },
    ...(variantOptionId && {
      relationships: {
        "selected-variant-options": { data: [{ id: variantOptionId }] },
      },
    }),
  },
});

const multiVariantBody = ({ itemId, isActive, variantOptions }) => {
  return {
    data: {
      attributes: {
        ...(itemId && { "item-id": itemId }),
        "is-active": isActive ?? true,
      },
      ...(variantOptions &&
        variantOptions.length > 0 && {
          relationships: {
            "selected-variant-options": {
              data: variantOptions.map((v) => ({
                id: v,
              })),
            },
          },
        }),
    },
  };
};

export const handleMultiVariantUpdates = async ({
  dispatch,
  itemId, // (string) Id for the item that the item program is based off of
  existingVariants, // (array) Variants that exist currently on an item program
  sizeVariantIds, // (array) User selected size variant ids
  teamVariantIds, // (array) User selected team variant ids
}) => {
  /**
   * We need to check to see if variant combinations already exist.
   * Because many combinations are available we will use an object instead of an array for speed
   * We thus need a key for each that's in a specific order
   */
  const makeVariantComboKey = (sizeId, teamId) => {
    return sizeId && teamId ? `${sizeId}-${teamId}` : sizeId || teamId || "";
  };

  if (!sizeVariantIds || !teamVariantIds) return;

  // Create size/team combinations.
  const sizeTeamCombinations = sizeVariantIds.flatMap((sizeId) =>
    teamVariantIds.map((teamId) => ({
      size: sizeId,
      team: teamId,
    }))
  );

  // When a unique variant combination is found on a variant, add the variant to an array
  const variantMap = existingVariants.reduce((arr, variant) => {
    const variantOptionSize = variant.selectedVariantOptions.find(
      (varOpt) => varOpt["variant-category"].name === VariantCategoryName.Sizes
    );
    const variantOptionTeam = variant.selectedVariantOptions.find(
      (varOpt) => varOpt["variant-category"].name === VariantCategoryName.Sports
    );
    const key = makeVariantComboKey(
      variantOptionSize ? variantOptionSize.id : null,
      variantOptionTeam ? variantOptionTeam.id : null
    );
    if (!arr[key]) {
      arr[key] = variant;
    }
    return arr;
  }, {});

  // Set up deleting of variants that were active and for which the combo no longer exits for
  const actions = {
    create: [],
    activate: [],
    deactivate: Object.keys(variantMap)
      .filter((id) => {
        const combinationFound = sizeTeamCombinations.some(
          (combination) =>
            makeVariantComboKey(combination.size, combination.team) === id
        );
        return !combinationFound && variantMap[id].isActive;
      })
      .map((optionId) => variantMap[optionId].id),
  };

  // Create the activate and create actions
  sizeTeamCombinations.forEach((combination) => {
    const combinationId = makeVariantComboKey(
      combination.size,
      combination.team
    );
    const v = variantMap[combinationId];
    if (v && !v.isActive) {
      actions.activate.push(v.id);
    } else if (!v) {
      actions.create.push([combination.size, combination.team]);
    }
  });

  const createActions = actions.create.map((varOptComboIds) => ({
    actionType: "create",
    varOptComboIds: varOptComboIds,
  }));
  const activateActions = actions.activate.map((variantId) => ({
    actionType: "activate",
    variantId: variantId,
  }));
  const deleteActions = actions.deactivate.map((variantId) => ({
    actionType: "deactivate",
    variantId: variantId,
  }));

  const actionArray = [...createActions, ...activateActions, ...deleteActions];

  const rejectOnError = (promise) =>
    new Promise(async (resolve, reject) => {
      const res = await promise;
      if (res.status === "error") {
        reject(res);
        return;
      }
      resolve(res);
    });

  const iteratorFunc = (action) => {
    if (action.actionType === "create") {
      return rejectOnError(
        axiosPost(
          "/api/variants",
          multiVariantBody({ itemId, variantOptions: action.varOptComboIds })
        )
      );
    } else if (action.actionType === "activate") {
      return rejectOnError(
        axiosPut(
          `/api/variants/${action.variantId}`,
          multiVariantBody({ isActive: true })
        )
      );
    } else if (action.actionType === "deactivate") {
      return rejectOnError(
        axiosPut(
          `/api/variants/${action.variantId}`,
          multiVariantBody({ isActive: false })
        )
      );
    }
  };

  dispatch(
    setIsStepper({
      stepBool: true,
      stepTitle: "Updating variants...",
    })
  );

  const stepIncrement = 100 / actionArray.length;

  const updateStepper = () => {
    dispatch(updateStepperValue({ value: stepIncrement }));
  };

  const { results, errors } = await asyncPool(
    5,
    actionArray,
    iteratorFunc,
    updateStepper
  );

  dispatch(resetStepperValue());

  return { results, errors };
};

export const handleVariantUpdates = ({
  itemId,
  variants,
  variantOptionsIds: voIds,
}) => {
  if (!voIds) return;

  // Create a map from the variants array for quick lookup. ("sizeId-teamId")
  const variantMap = variants.reduce((acc, variant) => {
    const key = variant.selectedVariantOptions.map((v) => v.id).join("-");
    if (key) acc[key] = variant;
    return acc;
  }, {});

  const actions = {
    create: [],
    activate: [],
    deactivate: [],
  };

  // Iterate through the variantOptionsIds to determine which variants to create, activate, or deactivate.
  voIds.forEach((id) => {
    const variant = variantMap[id];

    if (variant) {
      // If the variant exists but is not active, schedule for activation.
      if (!variant.isActive) {
        actions.activate.push(variant.id);
      }
    } else {
      // If the variant does not exist, schedule for creation.
      actions.create.push(id);
    }
  });

  // Iterate through the variantMap to determine which active variants are not in variantOptionsIds and should be deactivated.
  Object.keys(variantMap).forEach((id) => {
    if (!voIds.includes(id) && variantMap[id].isActive) {
      actions.deactivate.push(variantMap[id].id);
    }
  });

  return Promise.all(
    [
      ...actions.create.map((variantOptionId) =>
        axiosPost("/api/variants", variantBody({ itemId, variantOptionId }))
      ),
      ...actions.activate.map((id) =>
        axiosPut(`/api/variants/${id}`, variantBody({ isActive: true }))
      ),
      ...actions.deactivate.map((id) =>
        axiosPut(`/api/variants/${id}`, variantBody({ isActive: false }))
      ),
    ].map(async (reqPromise) => {
      const res = await reqPromise;
      if (res.error) throw res.error;
    })
  );
};
