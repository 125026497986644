/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { CloudUpload, GetApp } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CSVLink } from "@utils/csv";

import {
  clearVariablePricingDataExport,
  fetchVariablePricingDataExport,
} from "../../redux/slices/variablePricing/variablePricingSlice";
import CsvTable from "../Utility/CsvTable";
import VariablePricingImportModal from "./VariablePricingImportModal";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const VariablePricing = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Imperial/Sterling");
  const { dataExport, isLoading: isDataLoading } = useSelector(
    (state) => state.variablePricing
  );

  useEffect(() => {
    dispatch(fetchVariablePricingDataExport(selectedTab));
    return () => dispatch(clearVariablePricingDataExport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <Box tw="grid grid-rows-[auto 1fr] gap-8 h-[calc(100vh - 140px)]">
      <VariablePricingImportModal
        open={importModalOpen}
        onClose={() => {
          setImportModalOpen(false);
          dispatch(fetchVariablePricingDataExport(selectedTab));
        }}
      />
      <div className={classes.titleBar}>
        <Typography className={classes.titleText}>
          Edit Spec Variable Pricing
        </Typography>

        <Button
          className={classes.largeButton}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (selectedTab === "Imperial/Sterling") {
              setSelectedTab("Halo");
            } else {
              setSelectedTab("Imperial/Sterling");
            }
          }}
        >
          Show
          {selectedTab === "Imperial/Sterling" ? " Halo" : " Imperial/Sterling"}
        </Button>

        <div className={classes.innerConfigDiv}>
          <Button
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            style={{ marginRight: "10px" }}
            startIcon={<CloudUpload />}
            onClick={() => setImportModalOpen(true)}
          >
            Import
          </Button>
          <CSVLink data={dataExport} filename="variable_pricing.csv">
            <Button
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              style={{ marginRight: "10px" }}
              disabled={isDataLoading}
              startIcon={<GetApp />}
            >
              Export
            </Button>
          </CSVLink>
        </div>
      </div>
      <Box tw="overflow-auto">
        {isDataLoading && <CircularProgress />}
        {dataExport.length > 0 ? (
          <CsvTable data={dataExport} csvDelimiter="," />
        ) : (
          !isDataLoading && (
            <Typography>No Variable Pricing Info Found</Typography>
          )
        )}
      </Box>
    </Box>
  );
};

export default VariablePricing;
