import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { __ } from "lodash/fp";

import { ItemSubType, ItemType } from "@models/ItemType";
import client from "@services/api";

export const itemTypesKeyFactory = createQueryKeys("item-types", {
  list: {
    queryKey: null,
    queryFn: () =>
      client
        .get<ItemType[]>("item-types", {
          params: { filter: { isActive: true } },
        })
        .then((res) => res.data),
  },
});

export const useItemTypesQuery = () => {
  return useQuery({
    ...itemTypesKeyFactory.list,
    staleTime: Infinity,
    structuralSharing: true,
  });
};

type ItemTypePayload = { id: string } & Partial<ItemType>;

export const useUpdateItemTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: ItemTypePayload) => {
      return client
        .update<ItemType>(`item-types/${id}`, { __type: "item-type", ...data })
        .then((res) => res.data);
    },
    onSuccess: (itemType) => {
      queryClient.setQueryData<ItemType[]>(
        itemTypesKeyFactory.list.queryKey,
        (itemTypes) =>
          itemTypes &&
          itemTypes.map((it) => {
            if (it.id === itemType.id) {
              return itemType;
            } else {
              return it;
            }
          })
      );
    },
  });
};

type ItemSubTypePayload = {
  id: string;
  itemTypeId: string;
  label: string;
  moq: number | string;
  estimatedCost: string;
  complexityScore: string;
};

export const useUpdateItemSubTypeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, itemTypeId: _, ...data }: ItemSubTypePayload) => {
      console.log(id);
      return client
        .update<ItemSubType>(`/item-sub-types/${id}`, {
          __type: "item-sub-type",
          ...data,
        })
        .then((res) => res.data);
    },
    onSuccess: (itemSubType, { itemTypeId }) => {
      queryClient.setQueryData<ItemType[]>(
        itemTypesKeyFactory.list.queryKey,
        (itemTypes) =>
          itemTypes &&
          itemTypes.map((it) => {
            if (it.id === itemTypeId) {
              return {
                ...it,
                itemSubTypes: it.itemSubTypes.map((st) => {
                  if (st.id === itemSubType.id) {
                    return itemSubType;
                  } else {
                    return st;
                  }
                }),
              };
            } else {
              return it;
            }
          })
      );
    },
    onError: (e) => console.log(e),
  });
};

export const useCreateItemSubTypeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ itemTypeId, ...data }: Omit<ItemSubTypePayload, "id">) =>
      client
        .post<ItemSubType>("/item-sub-types", {
          __type: "item-sub-type",
          ...data,
          itemType: { type: "item-type", id: itemTypeId },
          relationshipNames: ["itemType"],
        })
        .then((res) => res.data),
    onSuccess: (itemSubType, { itemTypeId }) => {
      queryClient.setQueryData<ItemType[]>(
        itemTypesKeyFactory.list.queryKey,
        (itemTypes) =>
          itemTypes &&
          itemTypes.map((it) => {
            if (it.id === itemTypeId) {
              return {
                ...it,
                itemSubTypes: [...it.itemSubTypes, itemSubType],
              };
            } else {
              return it;
            }
          })
      );
    },
  });
};
