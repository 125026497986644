import { without } from "lodash";

export const orderApprovalTypes = [
  "coupon-item-type",
  "coupon-offer-type",
  "coupon-face-value",
  "digital-coupon",
  "item-type",
  "metal-wood",
  "price",
  "progressive-offer",
];

// These rule types prevent assigning quantity to an order variant
// from the order-set
export const orderVariantNotCompliantTypes = without(
  orderApprovalTypes,
  "price"
);

export const itemApprovalTypes = [
  "coupon-prior-approval",
  "coupon-internal-approval",
  "prior-approval",
  "internal-approval",
];
