/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Button } from "@mui/material";

import { SmartTable } from "src/components/SmartTable";
import { PaginatedResourceProps } from "src/components/SmartTable/SmartTable";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { ItemSubType, ItemType } from "@models/ItemType";

type ItemTypeTableProps = PaginatedResourceProps & {
  data: ItemType[];
  onItemTypeClick: (itemType: ItemType) => void;
  onSubTypeClick: (subType: ItemSubType | null, itemTypeId: string) => void;
};

const ItemTypeTable = ({
  data,
  onItemTypeClick,
  onSubTypeClick,
  ...tableProps
}: ItemTypeTableProps) => {
  return (
    <SmartTable
      rows={data}
      {...tableProps}
      columns={[
        {
          id: "externalId",
          label: "Item Type Code",
          render: (externalId) => <b>#{externalId}</b>,
        },
        { id: "categoryCode", label: "Category Code" },
        { id: "description", label: "Description" },
        { id: "qtyPerPack", label: "Pack Size" },
        { id: "leadTimeInDays", label: "Lead Time In Days" },
        { id: "specType", label: "Spec Type" },
        { id: "posType", label: "POS Type" },
      ]}
      onRowClick={onItemTypeClick}
      collapsibleContent={(itemType) => (
        <div>
          <SmartTable
            tw="bg-white"
            rows={itemType.itemSubTypes}
            columns={[
              { id: "label", label: "Label" },
              {
                id: "estimatedCost",
                label: "Estimated Cost",
                render: formatMoneyString,
              },
              { id: "moq", label: "MOQ" },
              { id: "leadTimeInDays", label: "Lead Time In Days" },
              { id: "complexityScore", label: "Complexity Score" },
            ]}
            onRowClick={(subType) => onSubTypeClick(subType, itemType.id)}
          />
          <Button
            onClick={() => onSubTypeClick(null, itemType.id)}
            color="secondary"
            size="small"
          >
            Add Sub-Type
          </Button>
        </div>
      )}
    />
  );
};

export default ItemTypeTable;
