import format from "date-fns/format";

import { stringToCents } from "../../../utility/utilityFunctions";
import { handleImages, mapSpecifications } from "../../mapHelpers";
import { mapItems } from "../items/maps";

const typeMap = {
  "prior-approval": "Prior Approval",
  "coupon-prior-approval": "Coupon Prior Approval",
  "internal-approval": "Internal Approval",
  "coupon-internal-approval": "Coupon Internal Approval",
  price: "Price",
  "item-type": "Item Type",
  material: "Material",
  "metal-wood": "Metal / Wood",
  "coupon-offer-type": "Coupon Offer Type",
  "coupon-item-type": "Coupon Item Type",
  "coupon-face-value": "Coupon Face Value",
};

export const mapCompItems = (items) => {
  const statusMap = {
    "prior-approval-pending": "Pending",
    "in-violation": "In Violation",
    approved: "Approved",
    denied: "Denied",
  };
  let mappedItems = items.map((item) => {
    const images = handleImages(item["triggered-item-images"]);
    return {
      id: item.id,
      itemNumber: item["item-number"],
      program: item["most-recent-program-name"],
      brand: item["brand-names"] ? item["brand-names"] : "---",
      itemType: item["item-type-description"],
      itemDescription: item["item-comment"] ? item["item-comment"] : "---",
      estCost: stringToCents(item["item-estimated-cost"]),
      packSize: item["item-qty-per-pack"],
      removeFromCatalogDate: item["item-remove-from-catalog-date"],
      specification: item["item-specification"]
        ? mapSpecifications(item["item-specification"], "item-display")
        : null,
      imgUrlThumb: images.imgUrlThumb,
      imgUrlLg: images.imgUrlLg,
      ruleType: typeMap[item.rule.type],
      ruleDesc: item.rule.description,
      status: statusMap[item.status],
      state: item.state.code,
      emailSent: item["approval-email-sent"],
      emailSentDate: item["email-sent-at"]
        ? format(new Date(item["email-sent-at"]), "MM/dd/yyyy")
        : "---",
      actionDate: item["action-date"]
        ? format(new Date(item["action-date"]), "MM/dd/yyyy")
        : "---",
      reviewedBy: item["reviewed-by"],
      //This is placeholder for now
      active: true,
    };
  });
  return mappedItems;
};

export const mapRules = (rules) => {
  let mappedRules = rules.map((rule) => ({
    id: rule.id,
    ruleType: typeMap[rule.type],
    desc: rule.description,
    itemTypeCode: rule["item-type-category-code"]
      ? rule["item-type-category-code"]
      : "---",
    itemTypes:
      rule["item-type-descriptions"].length > 0
        ? rule["item-type-descriptions"].join(", ")
        : "---",
    price: rule.price ? stringToCents(rule.price) : "---",
    productFamilies:
      rule["product-family-names"].length > 0
        ? rule["product-family-names"].join(", ")
        : "---",
    states: rule.states.map((s) => s.code).join(", "),
    isActive: rule["is-active"],
    insertedAt: format(new Date(rule["inserted-at"]), "PP p"),
    updatedAt: format(new Date(rule["updated-at"]), "PP p"),
  }));
  return mappedRules;
};

export const mapOverrideRules = (item, overrides, rules) => {
  let itemData = mapItems([item])[0];

  let ruleData = rules.reduce((acc, rule) => {
    let ruleStates = [];
    rule.states.forEach((s) => {
      let row = {
        item: { ...itemData },
        itemId: itemData.id,
        ruleId: rule.id,
        ruleType: typeMap[rule.type],
        desc: rule.description,
        stateId: s.id,
        stateCode: s.code,
        overrideId: null,
        identifier: `${itemData.id}-${rule.id}-${s.id}`,
      };
      ruleStates.push(row);
    });

    return acc.concat(ruleStates);
  }, []);

  let overrideData = ruleData.map((r) => {
    let currentOverride = overrides.find(
      (o) =>
        +o["item-id"] === +item.id &&
        +o["rule-id"] === +r.ruleId &&
        +o["state-id"] === +r.stateId
    );

    if (currentOverride) {
      return {
        ...r,
        overrideId: currentOverride.id,
        overriddenBy: currentOverride["user-name"],
        overriddenAt: format(
          new Date(currentOverride["inserted-at"]),
          "MM/dd/yyyy"
        ),
        note: currentOverride.note ?? "---",
      };
    } else return r;
  });

  return overrideData;
};
