/** @jsxImportSource @emotion/react */
import tw, { css, styled } from "twin.macro";

import { useMemo } from "react";

import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { isFuture } from "date-fns";
import _ from "lodash";
import { formatDate } from "src/utility/utilityFunctions";

import ControlledBrandAutocomplete from "@utils/forms/ControlledBrandAutocomplete";

import {
  useItemTypes,
  useOrderCalendarMonthOptions,
  useProgramInfo,
} from "../../../hooks/planningToolHooks";
import {
  intValidation,
  moneyAdornment,
  moneyValidation,
} from "../../../utility/inputHelpers";
import {
  CheckboxInput,
  DateInput,
  SelectInput,
  TextInput,
} from "../../Forms/ControlledInputs";
import FormItemTypeSelector from "./FormItemTypeSelector";

const FormRow = styled.div(
  css(tw`flex items-start justify-start gap-x-4`, {
    "& > *": tw`flex-1`,
  })
);

const designTypeOptions = [
  { label: "EPR", id: "epr" },
  { label: "CPR", id: "cpr" },
];
const complexityScoreOptions = [
  { label: "Low", id: "low" },
  { label: "Medium", id: "medium" },
  { label: "High", id: "high" },
];

const ItemForm = ({ form, controlled, item }) => {
  const { watch, getValues, setValue } = form;
  const {
    requiresOCM,
    orderType,
    channel: programChannel,
    brandAssignmentLevel,
  } = useProgramInfo();

  const [
    itemTypeId,
    itemSubTypeId,
    orderableStartDate,
    becomesOnDemand,
    includeBeacon,
  ] = watch([
    "itemTypeId",
    "itemSubTypeId",
    "orderableStartDate",
    "becomesOnDemand",
    "includeBeacon",
  ]);

  const ocmOptions = useOrderCalendarMonthOptions(item?.orderCalendarMonth);

  const itemTypes = useItemTypes();
  const itemType = useMemo(
    () => itemTypes.find(({ id }) => id === itemTypeId),
    [itemTypes, itemTypeId]
  );
  const subTypeOptions = useMemo(() => {
    if (!itemType) return [];
    return itemType.itemSubTypes.map(({ label, id }) => ({
      label,
      id,
    }));
  }, [itemType]);

  const ocmIsOnDemand = watch("orderCalendarMonthId") === "on-demand";
  const canSetBeaconCost = programChannel !== "on_premise";

  const handleChangeItemSubType = (itemSubTypeId, subType) => {
    subType =
      subType ?? itemType?.itemSubTypes.find(({ id }) => id === itemSubTypeId);
    if (!subType) return;
    // If they aren't empty, set them as defaults
    Object.entries(
      _.pick(subType, ["complexityScore", "estimatedCost", "qtyPerPack", "moq"])
    ).forEach(([key, value]) => value && setValue(key, String(value)));
  };
  const handleChangeItemType = (selectedItemType) => {
    // Handle set defaults from item Type
    Object.entries(
      _.pick(selectedItemType, [
        "qtyPerPack",
        "sendToContentHub",
        "rtaDeployment",
      ])
    ).forEach(([key, value]) => value !== null && setValue(key, value));

    // If the itemType has changed, or the itemSubtype is already set
    // set if itemSubTypeId is not in the list of ids
    const { itemSubTypes } = selectedItemType;
    if (!itemSubTypes.find(({ id }) => id === itemSubTypeId)) {
      const defaultOption =
        itemSubTypes.find(({ label }) => label === "Default") ||
        itemSubTypes[0];
      handleChangeItemSubType(defaultOption?.id, defaultOption);
      setValue("itemSubTypeId", defaultOption?.id || "");
    }
  };

  const setDefaultBeaconCost = (e) => {
    if (e.target.value && Number(getValues("beaconCost")) === 0) {
      setValue("beaconCost", canSetBeaconCost ? "9.00" : "0.00");
    }
  };

  return (
    <div tw="space-y-4">
      <FormRow>
        <FormItemTypeSelector
          tw="flex-[1]!"
          {...controlled("itemTypeId", { required: true })}
          onChange={handleChangeItemType}
        />
        {brandAssignmentLevel === "item" && (
          <ControlledBrandAutocomplete
            tw="flex-[2]!"
            label="Brand"
            limitTags={1}
            multiple
            {...controlled("brands", { required: true })}
          />
        )}
        {requiresOCM && (
          <SelectInput
            tw="min-w-min"
            label="Order Window"
            options={ocmOptions}
            {...controlled("orderCalendarMonthId", { required: true })}
            onChange={(e) => {
              if (e.target.value === "on-demand") {
                setValue("becomesOnDemand", true);
              }
            }}
          />
        )}
        <TextInput
          tw="flex-[2]!"
          label="Description"
          {...controlled("comment")}
        />
        <SelectInput
          label="Design Type"
          options={designTypeOptions}
          {...controlled("designType")}
        />
      </FormRow>
      <FormRow>
        <SelectInput
          tw="flex-[1.5]!"
          label="Item Sub Type"
          options={subTypeOptions}
          onChange={(e) => handleChangeItemSubType(e.target.value)}
          {...controlled("itemSubTypeId")}
          startAdornment={
            <Tooltip title="Changing the Item Sub Type will trigger fields on this line to reset to the Sub Type's default values.">
              <InfoOutlined tw="mr-1 text-gray-500 text-lg" />
            </Tooltip>
          }
        />
        <TextInput
          label="Estimated price"
          InputProps={moneyAdornment}
          {...controlled("estimatedCost", moneyValidation)}
        />
        <TextInput
          label="Pack size"
          {...controlled("qtyPerPack", {
            ...intValidation,
            transformOnBlur: (v) => String(+v || 1),
          })}
        />
        <TextInput label="MOQ" {...controlled("moq", intValidation)} />
        <SelectInput
          label="Complexity Score"
          options={complexityScoreOptions}
          {...controlled("complexityScore")}
        />
      </FormRow>
      <FormRow>
        {includeBeacon && (
          <TextInput
            label="Beacon Cost"
            InputProps={moneyAdornment}
            {...controlled("beaconCost", moneyValidation)}
            disabled={!canSetBeaconCost}
          />
        )}
        <DateInput
          tw="grow pointer-events-auto"
          label="Remove from RTA Date"
          disablePast={item?.id ? false : true}
          {...controlled("removeFromCatalogDate", {
            ...(!Boolean(item?.id) && {
              validate: (v) => isFuture(new Date(v)),
            }),
          })}
        />
        <TextInput
          label="Art Reference Number"
          {...controlled("artReferenceNumber")}
        />
      </FormRow>

      <div tw="grid grid-cols-2 lg:grid-cols-[2fr auto auto] gap-4">
        <div tw="flex flex-col space-y-4 justify-start col-span-2 lg:col-span-1">
          {(orderType === "on-demand" ||
            (orderType === "pre-order" &&
              (ocmIsOnDemand || becomesOnDemand))) && (
            <FormRow>
              <DateInput
                label="Orderability Start Date"
                {...controlled("orderableStartDate")}
              />
              <DateInput
                label="Orderability End Date"
                minDate={formatDate(orderableStartDate)}
                minDateMessage="Cannot be before orderability start date"
                {...controlled("orderableEndDate")}
              />
            </FormRow>
          )}
          <TextInput
            multiline
            minRows={2}
            maxRows={5}
            label="Creative Instructions"
            {...controlled("creativeInstructions")}
          />
          <TextInput
            multiline
            minRows={1}
            maxRows={5}
            label="Other notes"
            {...controlled("otherNotes")}
          />
        </div>
        <div tw="flex flex-col">
          <CheckboxInput label="Active" {...controlled("isActive")} />
          <CheckboxInput
            label="RTA Deployment?"
            {...controlled("rtaDeployment")}
          />
          <CheckboxInput
            label="Content hub?"
            {...controlled("sendToContentHub")}
          />

          <CheckboxInput
            label="Becomes on demand?"
            {...controlled("becomesOnDemand")}
          />

          <CheckboxInput
            label="Is Sweepstakes?"
            {...controlled("isSweepstakes")}
          />
        </div>
        <div tw="flex flex-col">
          <CheckboxInput label="Is Inventory" {...controlled("isInventory")} />

          <CheckboxInput label="Is Accolade?" {...controlled("isAccolade")} />

          <CheckboxInput
            label="Is Metal or Wood?"
            {...controlled("isMetalOrWood")}
          />

          <CheckboxInput
            label="Include Beacon?"
            {...controlled("includeBeacon")}
            onChange={setDefaultBeaconCost}
          />
        </div>
      </div>
    </div>
  );
};

ItemForm.defaultProps = {
  disabledFields: [],
};

export default ItemForm;
