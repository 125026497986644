import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { ItemProgram } from "@models/ItemProgram";
import client, { RequestParams } from "@services/api";

export const itemsKeyFactory = createQueryKeys("item-programs", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<ItemProgram[]>("item-programs", {
          params: { ...params, skipPagination: true },
        })
        .then((res) => res.data),
  }),
});

export const useItemProgramListQuery = (params: RequestParams) => {
  return useQuery({
    ...itemsKeyFactory.list(params),
    enabled: !!params.filter,
  });
};
