import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import _ from "lodash";
import PropTypes from "prop-types";

import { useOnDemandNationalCouponPrograms } from "@features/programs";

import {
  setClear,
  setSorted,
  updateMultipleFilters,
} from "../../redux/slices/filterSlice";
import { TextInput } from "../Forms/ControlledInputs";
import { SelectField } from "../Forms/DefaultInputs";
import BUAutoComplete from "../Utility/AutoCompleteFields/BUAutoComplete";
import BrandAutoComplete from "../Utility/AutoCompleteFields/BrandAutoComplete";
import ItemTypeAutoComplete from "../Utility/AutoCompleteFields/ItemTypeAutoComplete";
import ProgramAutoComplete from "../Utility/AutoCompleteFields/ProgramAutoComplete";
import TerritoryAutoComplete from "../Utility/AutoCompleteFields/TerritoryAutoComplete";
import IsAccoladeSelector from "../Utility/Selectors/IsAccoladeSelector";
import ItemOrderTypeSelector from "../Utility/Selectors/ItemOrderTypeSelector";
import { executeOnEnter } from "./utility";

const FiltersItems = ({
  reset,
  setReset,
  handleFilters,
  classes,
  itemFilterType,
}) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset: resetForm,
    getValues,
  } = useForm({
    defaultValues: {
      itemNumber: "",
      itemDesc: "",
    },
  });

  const reg = (name) => ({ control, name });

  const [type, setType] = useState("all");
  const [isAccolade, setIsAccolade] = useState(null);

  const filters = useSelector((state) => state.filters);
  const favoriteItems = useSelector((state) => state.user.favoriteItems);
  const isLoading = useSelector((state) => state.globalLoad.isLoading);
  const currentUserRole = useSelector((state) => state.user.role);
  const programFilterId = filters.program?.[0]?.id;
  const programs = useOnDemandNationalCouponPrograms();
  const programOptions = programs.map((p) => ({ label: p.name, value: p.id }));

  const setProgramFilter = (id) => {
    handleFilters([programs.find((p) => p.id === id)], "program", "item-all");
  };

  const handleFreeTypeSearch = async (data) => {
    dispatch(
      updateMultipleFilters({
        filterObject: {
          ..._.mapValues(data, (v) => v || null),
          preventFetch: false,
        },
      })
    );
    // setSorted triggers the useEffect in FilterDrawer to
    // fetch the data _after_ the filters are updated
    dispatch(setSorted());
  };

  // Reset form to filter values when filters change
  useEffect(() => {
    const values = getValues();
    resetForm(
      _(filters)
        .pick(Object.keys(values))
        .mapValues((v) => v || "")
        .value()
    );
  }, [filters, resetForm, getValues]);

  useEffect(() => {
    if (reset) resetForm();
  }, [reset, resetForm]);

  return (
    <>
      <List
        component="form"
        onKeyPress={executeOnEnter(handleSubmit(handleFreeTypeSearch))}
        onSubmit={handleSubmit(handleFreeTypeSearch)}
      >
        <ListItem>
          <TextInput
            fullWidth
            label="Sequence #"
            {...reg("itemNumber")}
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <TextInput
            fullWidth
            label="Item Description"
            {...reg("itemDesc")}
            disabled={isLoading}
          />
        </ListItem>
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={handleSubmit(handleFreeTypeSearch)}
            disabled={isLoading}
          >
            SEARCH
          </Button>
        </ListItem>
        <ListItem />
        <Divider />
        <ListItem />
        {itemFilterType !== "nationalCoupons" && (
          <ListItem>
            <Button
              fullWidth
              className={classes.largeButton}
              variant="contained"
              color="secondary"
              disabled={favoriteItems.length === 0 || isLoading}
              onClick={() =>
                handleFilters([...favoriteItems], "favItems", "item-all")
              }
            >
              FILTER FAVORITES
            </Button>
          </ListItem>
        )}
        {(itemFilterType === "all" ||
          itemFilterType === "archive" ||
          itemFilterType === "visible") && (
          <ListItem>
            <ItemOrderTypeSelector
              classes={classes}
              handleOrderType={handleFilters}
              setType={setType}
              orderType={type}
              filterType={"item-all"}
            />
          </ListItem>
        )}
        <ListItem>
          <BrandAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"item-all"}
          />
        </ListItem>
        <ListItem>
          {itemFilterType === "nationalCoupons" ? (
            <SelectField
              label="Coupon Program"
              options={programOptions}
              fullWidth
              value={programFilterId ?? ""}
              onChange={(e) => setProgramFilter(e.target.value)}
            />
          ) : (
            <ProgramAutoComplete
              classes={classes}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={
                itemFilterType === "inventory" ? "item-inventory" : "item-all"
              }
            />
          )}
        </ListItem>
        <ListItem>
          <ItemTypeAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"item-all"}
          />
        </ListItem>
        <ListItem>
          <BUAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"item-all"}
          />
        </ListItem>
        <ListItem>
          <IsAccoladeSelector
            handleIsAccolade={handleFilters}
            isAccolade={isAccolade}
            setIsAccolade={setIsAccolade}
            classes={classes}
            filterType={"item-all"}
          />
        </ListItem>
        {currentUserRole !== "field1" && currentUserRole !== "field2" && (
          <ListItem>
            <Typography className={classes.bodyText}>
              Exclusive Territory:
            </Typography>
          </ListItem>
        )}
        <ListItem>
          <TerritoryAutoComplete
            classes={classes}
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType={"item-all"}
            type="exclusive"
          />
        </ListItem>
        <Divider />
        <ListItem />
        <ListItem>
          <Button
            fullWidth
            className={classes.largeButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(setClear());
            }}
            disabled={isLoading}
          >
            CLEAR FILTERS
          </Button>
        </ListItem>
      </List>
    </>
  );
};

FiltersItems.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default FiltersItems;
