/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import { StyledButton } from "src/components/StyledComponents";

import { ItemSubType } from "@models/ItemType";

import { TextInput } from "../../../components/Forms/ControlledInputs";
import {
  intValidation,
  moneyAdornment,
  moneyValidation,
} from "../../../utility/inputHelpers";
import {
  useCreateItemSubTypeMutation,
  useUpdateItemSubTypeMutation,
} from "../data";

const formDataFromItemSubType = (itemSubType: ItemSubType | null) => ({
  label: itemSubType?.label ?? "",
  moq: itemSubType?.moq ?? "",
  estimatedCost: itemSubType?.estimatedCost ?? "",
  complexityScore: itemSubType?.complexityScore ?? "",
});

type TData = ReturnType<typeof formDataFromItemSubType>;

const ItemSubTypeModal = ({
  handleClose,
  itemSubType,
  itemTypeId,
}: {
  handleClose: () => void;
  /* The itemTypeId of the parent item-type */
  itemSubType: ItemSubType | null;
  itemTypeId: string;
}) => {
  const updateItemSubTypeByIdMutation = useUpdateItemSubTypeMutation();
  const createItemSubTypeMutation = useCreateItemSubTypeMutation();
  const isMutationPending =
    updateItemSubTypeByIdMutation.isPending ||
    createItemSubTypeMutation.isPending;

  const { handleSubmit, control } = useForm({
    defaultValues: formDataFromItemSubType(itemSubType),
  });

  const controlled = (name, rules?) => ({
    name,
    control,
    rules,
  });

  const onSubmit = (data: TData) => {
    if (itemSubType) {
      console.log(data);
      updateItemSubTypeByIdMutation.mutate(
        {
          id: itemSubType.id,
          itemTypeId: itemTypeId,
          ...data,
        },
        { onSuccess: () => handleClose() }
      );
    } else {
      createItemSubTypeMutation.mutate(
        {
          itemTypeId: itemTypeId,
          ...data,
        },
        { onSuccess: () => handleClose() }
      );
    }
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle tw="flex items-center justify-between">
        {itemSubType ? `Editing ${itemSubType.label}` : "New Item Sub-Type"}
        <IconButton edge="end" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} tw="mt-2 space-y-4">
          <TextInput
            label="Label"
            fullWidth
            disabled={itemSubType?.label === "Default"}
            {...controlled("label", { required: true })}
          />
          <TextInput
            label="MOQ"
            fullWidth
            {...controlled("moq", intValidation)}
          />
          <TextInput
            label="Estimated Cost"
            fullWidth
            InputProps={moneyAdornment}
            {...controlled("estimatedCost", moneyValidation)}
          />
          <TextInput
            label="Complexity Score"
            fullWidth
            {...controlled("complexityScore", { required: true })}
          />
          <StyledButton
            cta
            fullWidth
            onClick={handleSubmit(onSubmit)}
            loading={isMutationPending}
          >
            Save
          </StyledButton>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ItemSubTypeModal);
