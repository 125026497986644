export const financeTrackerHeaders = [
  { label: "Ordered By", key: "user" },
  { label: "Brand", key: "brandCode" },
  { label: "Market", key: "territory" },
  { label: "Cost to Drop In", key: "dropCost" },
  { label: "Month in Market", key: "inMarketMonth" },
  { label: "", key: "blankA" },
  { label: "Item Type", key: "itemType" },
  { label: "", key: "blankB" },
  { label: "", key: "blankC" },
  { label: "Qty Ordered", key: "totalQty" },
  { label: "Seq #", key: "itemNumber" },
  { label: "Program", key: "program" },
  { label: "BU", key: "unit" },
  { label: "Estimated Cost", key: "estCost" },
  { label: "Actual Cost", key: "actCost" },
  { label: "Order Type", key: "orderType" },
  { label: "State", key: "state" },
  { label: "Brand Funded", key: "brandFunded" },
  { label: "Coupon Redemption Value", key: "couponValue" },
];

export const focusSummaryHeaders = [
  { label: "Supplier", key: "supplier" },
  { label: "Category", key: "programType" },
  { label: "Brands", key: "brands" },
  { label: "Business Units", key: "bus" },
  { label: "Program", key: "program" },
  { label: "Seq #", key: "itemNumber" },
  { label: "Item Type Code", key: "itemTypeCode" },
  { label: "Item Type", key: "itemType" },
  { label: "Item Description", key: "itemDesc" },
  { label: "Total Quantity", key: "totalQty" },
  { label: "Est. Cost", key: "estCost" },
  { label: "Total Est. Cost", key: "totalEstCost" },
  { label: "Standard Spec Code", key: "standardSpecCode" },
  { label: "Spec Pricing Tier", key: "standardSpecQtyTier" },
  { label: "Spec Quantity Tier", key: "priceBasedOnQty" },
];

export const rfpSummaryHeaders = [
  { label: "RFP #", key: "id" },
  { label: "Supplier", key: "supplier" },
  { label: "Category", key: "programType" },
  { label: "Brands", key: "brands" },
  { label: "Business Units", key: "bus" },
  { label: "Program", key: "program" },
  { label: "Seq #", key: "itemNumber" },
  { label: "Item Type Code", key: "itemTypeCode" },
  { label: "Item Type", key: "itemType" },
  { label: "Item Description", key: "itemDesc" },
  { label: "Total Quantity", key: "totalQty" },
  { label: "Est. Cost", key: "estCost" },
  { label: "Bids", key: "bids" },
  { label: "Bids with 5% Markup", key: "bidsWithMarkup" },
  { label: "Bid Statuses", key: "bidStatuses" },
];

export const focusDetailHeaders = [
  { label: "Seq #", key: "itemNumber" },
  { label: "Programs", key: "program" },
  { label: "Brands", key: "brands" },
  { label: "Business Units", key: "bus" },
  { label: "Territory", key: "territory" },
  { label: "ABN", key: "addressABN" },
  { label: "Distributor / Name", key: "addressName" },
  { label: "Address One", key: "addressOne" },
  { label: "Address Two", key: "addressTwo" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip", key: "zip" },
  { label: "Tactic", key: "isCoupon" },
  { label: "Item Type Code", key: "itemTypeCode" },
  { label: "Item Type", key: "itemType" },
  { label: "Item Description", key: "itemDesc" },
  { label: "Supplier", key: "supplier" },
  { label: "Total Quantity", key: "totalQty" },
  { label: "Est. Cost", key: "estCost" },
  { label: "Total Est. Cost", key: "totalEstCost" },
  { label: "Extended Cost w/ Est. Tax", key: "totalEstWithTax" },
  { label: "Ordered By", key: "user" },
  { label: "Status", key: "status" },
  { label: "Standard Spec Code", key: "standardSpecCode" },
];

export const poComplianceHeaders = [
  { label: "PO #", key: "poId" },
  { label: "Ordered By", key: "orderedBy" },
  { label: "Purchaser", key: "purchaser" },
  { label: "Supplier", key: "supplier" },
  { label: "Seq #", key: "itemNumber" },
  { label: "Programs", key: "program" },
  { label: "Brand Codes", key: "brandCode" },
  { label: "Brands", key: "brand" },
  { label: "Item Type Code", key: "itemTypeCode" },
  { label: "Item Type", key: "itemType" },
  { label: "Rule Type", key: "ruleType" },
  { label: "Rule Description", key: "ruleDesc" },
  { label: "Rule Status", key: "status" },
  { label: "State", key: "state" },
  { label: "Territory", key: "territory" },
  { label: "In Market Date", key: "inMarketDate" },
  { label: "Order Type", key: "orderType" },
  { label: "Email Sent", key: "emailSent" },
  { label: "Email Sent Date", key: "emailSentDate" },
];

export const inventoryHeaders = [
  { label: "Seq #", key: "itemNumber" },
  { label: "Warehouse", key: "warehouse" },
  { label: "Programs", key: "program" },
  { label: "Brands", key: "brand" },
  { label: "Brand Codes", key: "brandCode" },
  { label: "Business Units", key: "bu" },
  { label: "Item Type Code", key: "itemTypeCode" },
  { label: "Item Type", key: "itemType" },
  { label: "Item Description", key: "itemDesc" },
  { label: "Allocation Type", key: "allocationType" },
  { label: "Allocation Name", key: "allocationName" },
  { label: "Region", key: "region" },
  { label: "Available to order", key: "availableToOrderQty" },
  { label: "Est. Cost / Unit", key: "estCost" },
  { label: "Last Recieved Qty.", key: "mostRecentReceivedQty" },
  { label: "Last Received Date", key: "mostRecentReceivedDate" },
  { label: "Pending Ordered Qty", key: "pendingOrdered" },
  { label: "First Ship", key: "firstShip" },
  { label: "Last Ship", key: "lastShip" },
  { label: "90 Day Order Qty", key: "ninetyDayQty" },
  { label: "Year Order Qty", key: "yearQty" },
];

export const availableInventoryHeaders = [
  { label: "Seq #", key: "itemNumber" },
  { label: "Brands", key: "brand" },
  { label: "Brand Codes", key: "brandCode" },
  { label: "Business Units", key: "bu" },
  { label: "Item Type", key: "itemType" },
  { label: "Item Description", key: "itemDesc" },
  { label: "Allocation Type", key: "allocationType" },
  { label: "Allocation Name", key: "allocationName" },
  { label: "Available to Order", key: "availableToOrderQty" },
  { label: "Est. Cost / Unit", key: "estCost" },
];

export const roadmapForecastHeaders = [
  { label: "Order Type", key: "orderType" },
  { label: "Order Number", key: "orderNumber" },
  { label: "12 Digit Code", key: "anaplanCode" },
  { label: "Territory", key: "territory" },
  { label: "Brand", key: "brand" },
  { label: "Ordered By", key: "user" },
  { label: "Brand Code", key: "brandCode" },
  { label: "State", key: "state" },
  { label: "Total Cost", key: "totalCost" },
  { label: "In Market Date", key: "inMarketDate" },
  { label: "Tactic", key: "isCoupon" },
  { label: "Item Type", key: "itemType" },
  { label: "", key: "blank_1" },
  { label: "", key: "blank_2" },
  { label: "Total Qty", key: "qty" },
  { label: "Seq. #", key: "itemNumber" },
  { label: "Program", key: "program" },
  { label: "Item Description", key: "itemDesc" },
  { label: "Distributor / Address Name", key: "name" },
  { label: "Est. Cost / Unit", key: "estCost" },
  { label: "Act. Cost / Unit", key: "actCost" },
  { label: "Order Date", key: "orderDate" },
  { label: "Ship Date", key: "shipDate" },
  { label: "Carrier", key: "carrier" },
  { label: "Tracking #", key: "trackingNumber" },
  { label: "Status", key: "orderStatus" },
];

export const customCouponHeaders = [
  { label: "12-Digit ID", key: "orderId" },
  { label: "Type", key: "tactic" },
  { label: "Date Created", key: "createdAt" },
  { label: "Created By", key: "createdBy" },
  { label: "Ordered By", key: "userName" },
  { label: "Email", key: "userEmail" },
  { label: "Cart Type", key: "territoryType" },
  { label: "Territory", key: "territoryName" },
  { label: "Brand Code(s)", key: "brandCode" },
  { label: "Brand Name(s)", key: "brand" },
  { label: "Seq #", key: "itemNumber" },
  { label: "Barcode Id", key: "barcodeId" },
  { label: "Offer Type", key: "couponOfferTypeCode" },
  { label: "Version", key: "couponTypeCode" },
  { label: "Item Type Code", key: "itemTypeCode" },
  { label: "Item Type", key: "itemType" },
  { label: "Start Date", key: "couponStartDate" },
  { label: "Expiration Date", key: "couponExpirationDate" },
  { label: "Face Value", key: "couponFaceValue" },
  { label: "NAB Item", key: "isNABItem" },
  { label: "andType", key: "andType" },
  { label: "Offer Detail", key: "couponOfferDetail" },
  { label: "Total Qty", key: "totalQty" },
  { label: "State/Territory Qty", key: "stateTerrQty" },
  { label: "Shipping State", key: "state" },
  { label: "Brand Funded", key: "brandFunded" },
  { label: "Channel", key: "channel" },
  { label: "Program Type", key: "programType" },
];

export const cdcSummaryHeaders = [
  { label: "Supplier", key: "supplier" },
  { label: "Brand(s)", key: "brand" },
  { label: "Seq. #", key: "itemNumber" },
  { label: "Item Type", key: "itemType" },
  { label: "Total Quantity", key: "totalQty" },
  { label: "Comments", key: "comment" },
];

export const cdcShipListHeaders = [
  { label: "Param Item Id", key: "id" },
  { label: "Purchase Order #", key: "purchaseOrderNum" },
  { lable: "Supplier Reference", key: "supplierReference" },
  { label: "Key Account", key: "isKeyAccount" },
  { label: "Key Account Name", key: "territory" },
  { label: "Delivery Date", key: "inMarketDate" },
  { label: "Order Notes", key: "orderNotes" },
  { label: "ABN", key: "distributorAbn" },
  { label: "Distributor / Name", key: "addressName" },
  { label: "Address 1", key: "addressOne" },
  { label: "Address 2", key: "addressTwo" },
  { label: "Attention", key: "attn" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip", key: "zip" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Item Number", key: "itemNumber" },
  { label: "Labeling Info", key: "shippingLabel" },
  { label: "Total Quantity", key: "totalQty" },
  { label: "Order Approval Status", key: "complianceStatus" },
  { label: "Carrier", key: "carrier" },
  { label: "Ship Method", key: "method" },
  { label: "Actual Ship Date", key: "actShipDate" },
  { label: "Shipped Quantity", key: "shippedQty" },
  { label: "Package Count", key: "packageCount" },
  { label: "Tracking Number", key: "tracking" },
  { label: "Tax", key: "tax" },
];

export const cdcTransferAdviceHeaders = [
  { label: "Payload ID", key: "payloadId" },
  { label: "TimeStamp", key: "timeStamp" },
  { label: "PO NUMBER (Order ID)", key: "purchaseOrderNum" },
  { label: "Order Date", key: "nowDate" },
  { label: "Order Type", key: "orderType" },
  { label: "Total Money", key: "totalMoney" },
  { label: "Ship To Address ID (ABN)", key: "abn" },
  { label: "Ship To Name", key: "shipName" },
  { label: "Ship To Deliver To", key: "shipDeliverTo" },
  { label: "Ship To Street 1", key: "shipStreetOne" },
  { label: "Ship To Street 2", key: "shipStreetTwo" },
  { label: "Ship To City", key: "shipCity" },
  { label: "Ship To State", key: "shipState" },
  { label: "Ship To Postal", key: "shipZip" },
  { label: "Ship To Country", key: "shipCountry" },
  { label: "Bill To Name", key: "billName" },
  { label: "Bill To Deliver To", key: "billDeliverTo" },
  { label: "Bill To Street 1", key: "billStreetOne" },
  { label: "Bill To Street 2", key: "billStreetTwo" },
  { label: "Bill To City", key: "billCity" },
  { label: "Bill To State", key: "billState" },
  { label: "Bill To Postal", key: "billZip" },
  { label: "Bill To Country", key: "billCountry" },
  { label: "Shipping Money", key: "shipMoney" },
  { label: "Shipping Description", key: "shipDesc" },
  { label: "Shopper User Name", key: "shopperName" },
  { label: "Cost Center", key: "costCenter" },
  { label: "Request Type", key: "reqType" },
  { label: "Requested Ship Date", key: "inMarketDate" },
  { label: "Expected Arrival Date", key: "arrivalDate" },
  { label: "Producing Vendor", key: "supplier" },
  { label: "Vendor PO Number", key: "vendorNum" },
  { label: "Rapid Order Number", key: "rapidNum" },
  { label: "Date Time", key: "dateTime" },
  { label: "Payload ID", key: "payloadId2" },
  { label: "TimeStamp", key: "timeStamp2" },
  { label: "PO NUMBER (Order ID)", key: "purchaseOrderNum2" },
  { label: "Quantity", key: "totalQty" },
  { label: "Line Number", key: "lineNum" },
  { label: "Supplier Part Id", key: "itemNumber" },
  { label: "Unit Price", key: "unitPrice" },
  { label: "Description", key: "itemType" },
  { label: "Unit of Measure", key: "unitOfMeasure" },
  { label: "Classification", key: "classification" },
  { label: "Pack Size", key: "packSize" },
  { label: "Brand", key: "brand" },
  { label: "Brand Code", key: "brandCode" },
  { label: "Package Count", key: "packageCount" },
  { label: "Package Type", key: "packageType" },
  { label: "Tracking Number", key: "tracking" },
  { label: "Rapid Order Number", key: "rapidNum2" },
  { label: "Date Time", key: "dateTime2" },
];

export const supplierSummaryHeaders = [
  { label: "Supplier Name", key: "supplier" },
  { label: "Channel", key: "channel" },
  { label: "Total Purchase Orders", key: "totalPurchaseOrders" },
  { label: "Total Product Spend", key: "totalPOProductSpend" },
  { label: "Total Freight", key: "totalFreight" },
  { label: "Total Tax", key: "totalTax" },
  {
    label: "Total Purchase Order Amount (w/ Tax & Freight)",
    key: "totalPOAmount",
  },
];

export const supplierDetailHeaders = [
  { label: "Purchase Order #", key: "poId" },
  { label: "Supplier", key: "supplier" },
  { label: "Sequence #", key: "itemNumber" },
  { label: "Brand(s)", key: "brand" },
  { label: "Item Type", key: "itemType" },
  { label: "Quantity", key: "qty" },
  { label: "Cost / Unit", key: "actCost" },
  { label: "Total Cost", key: "totalCost" },
  { label: "Freight Cost", key: "shippingCost" },
  { label: "Tax", key: "tax" },
  { label: "Order Type", key: "orderType" },
  { label: "Program", key: "program" },
  { label: "Channel", key: "channel" },
  { label: "Carrier", key: "carrier" },
  { label: "Method", key: "method" },
  { label: "Setup Fee", key: "setUpFee" },
  { label: "PO Requested Date", key: "poRequestedDate" },
];

export const financeBillingHeaders = [
  { label: "GL Account", key: "glAccount" },
  { label: "Internal Order", key: "internalOrder" },
  { label: "Sequence #", key: "itemNumber" },
  { label: "Description", key: "itemType" },
  { label: "Territory", key: "state" },
  { label: "Invoice #", key: "invoiceNumber" },
  { label: "Quantity", key: "qty" },
  { label: "Total", key: "total" },
];

export const financeJournalHeaders = [
  { label: "GL Account", key: "glAccount" },
  { label: "Total", key: "total" },
  { label: "", key: "blankA" },
  { label: "", key: "blankB" },
  { label: "", key: "blankC" },
  { label: "", key: "blankD" },
  { label: "Internal Order", key: "internalOrder" },
  { label: "", key: "blankE" },
  { label: "Assignment", key: "state" },
  { label: "Item Text", key: "itemText" },
  { label: "", key: "blankF" },
  { label: "Quantity", key: "qty" },
  { label: "Base Unit", key: "baseUnit" },
  { label: "Supplier", key: "supplier" },
  { label: "Invoice #", key: "invoiceNumber" },
];

export const orderedItemsHeaders = [
  { label: "Sequence #", key: "itemNumber" },
  { label: "Brand(s)", key: "brand" },
  { label: "Item Type", key: "itemType" },
  { label: "Description", key: "itemDesc" },
  { label: "Order Type", key: "orderType" },
  { label: "Est. Cost", key: "estCost" },
  { label: "Total Qty", key: "totalQty" },
  { label: "Total Est. Cost", key: "totalEstCost" },
  { label: "Canceled Qty", key: "canceledQty" },
];

export const invoicingHeaders = [
  { label: "PO #", key: "poId" },
  { label: "PO Type", key: "orderType" },
  { label: "Sequence #", key: "itemNumber" },
  { label: "Supplier", key: "supplierName" },
  { label: "Brand", key: "brand" },
  { label: "Quantity", key: "qty" },
  { label: "Act. Cost/Unit", key: "actualCost" },
  { label: "Submitted Date", key: "submittedDate" },
  { label: "Ship Date", key: "shipDate" },
  { label: "Allocation", key: "allocation" },
];

export const invoiceDoubleCheckHeaders = [
  { label: "PO #", key: "poId" },
  { label: "Supplier", key: "supplier" },
  { label: "Sequence #", key: "itemNumber" },
  { label: "Brand", key: "brand" },
  { label: "Item Type", key: "itemType" },
  { label: "Actual Unit Cost", key: "actualCost" },
  { label: "Qty Ordered", key: "qty" },
  { label: "Extended Product Cost", key: "extendedProductCost" },
  { label: "Freight Total", key: "totalFreight" },
  { label: "Taxes Total", key: "totalTax" },
  { label: "Total", key: "totalCost" },
];

export const invoiceDetailHeaders = [
  { label: "Invoice #", key: "invoiceNumber" },
  { label: "Internal Order", key: "internalOrder" },
  { label: "Sequence #", key: "itemNumber" },
  { label: "Unit Cost", key: "actualCost" },
  { label: "Qty", key: "qty" },
  { label: "Extended Product Cost", key: "totalCost" },
  { label: "State Assignment", key: "stateAssignment" },
];

export const printInvoiceHeaders = [
  { label: "Posting Date", key: "postingDate" },
  { label: "Document Date", key: "documentDate" },
  { label: "Company Code", key: "companyCode" },
  { label: "Document Type", key: "documentType" },
  { label: "Currency", key: "currency" },
  {
    label: "Doc. Header Text (Key Element all associated records)",
    key: "headerText",
  },
  { label: "Reference (Key Element all associated records)", key: "reference" },
  { label: "Ledger Group", key: "ledgerGroup" },
  { label: "Reversal Date", key: "reversalDate" },
  { label: "Reversal Reason", key: "reversalReason" },
  { label: "Exchange Rates", key: "exchangeRates" },
  { label: "Translation Date", key: "translationDate" },
  { label: "Posting Key", key: "postingKey" },
  { label: "GL Account", key: "glAccount" },
  { label: "Amount in Document Currency", key: "cost" },
  { label: "Amount in Local Currency", key: "localCurrencyAmt" },
  { label: "Amount in Global Currency", key: "globalCurrencyAmt" },
  { label: "Cost Center", key: "costCenter" },
  { label: "Profit Center", key: "profitCenter" },
  { label: "Internal Order", key: "internalOrder" },
  { label: "Trading Partner", key: "tradingPartner" },
  { label: "Assignment", key: "state" },
  { label: "Item Text", key: "itemNumber" },
  { label: "CoCode", key: "coCode" },
  { label: "Quantity", key: "qty" },
  { label: "Base Unit of Measure", key: "unitOfMeasuer" },
  { label: "Tax on Sales/Purchases Code", key: "taxOnSales" },
  { label: "Tax Jurisdiction", key: "taxJurisdiction" },
  { label: "Bill-to Party", key: "billToParty" },
  { label: "Ship-to Party", key: "shipToParty" },
  { label: "Material Number", key: "materialNumber" },
  { label: "PLANT", key: "plant" },
  { label: "Profit_Ctr2", key: "profitCtr" },
  { label: "Channel", key: "channel" },
  { label: "Long Text", key: "longText" },
  { label: "BRAND", key: "brand" },
  { label: "REGION", key: "territoryName" },
  { label: "Gallo/Non-Gallo", key: "isGallo" },
  { label: "Division VIP", key: "divisionVIP" },
  { label: "Reference Key 1", key: "referenceKey" },
  { label: "Line Material", key: "lineMaterial" },
];

export const orderWindowItemsHeaders = [
  { label: "Sequence #", key: "itemNumber" },
  { label: "Program", key: "program" },
  { label: "Brands", key: "brand" },
  { label: "Item Type", key: "itemType" },
  { label: "Item Description", key: "itemDescription" },
  { label: "Est. Cost", key: "estCost" },
  { label: "Supplier", key: "supplier" },
  { label: "Order Window", key: "orderWindow" },
];

export const orderDetailHeaders = [
  { label: "Sequence Number", key: "itemNumber" },
  { label: "Order Window", key: "orderWindowName" },
  { label: "In Market Month", key: "inMarketMonth" },
  { label: "Quarter", key: "quarter" },
  { label: "PO #", key: "poNumber" },
  { label: "Order Type", key: "orderType" },
  { label: "Ordered By", key: "orderedBy" },
  { label: "Channel", key: "channel" },
  { label: "Territory", key: "territoryNames" },
  { label: "Brand", key: "brands" },
  { label: "Business Unit", key: "businessUnits" },
  { label: "Program Type", key: "programType" },
  { label: "Program", key: "program" },
  { label: "Item Type", key: "itemType" },
  { label: "Item Desc.", key: "itemDescription" },
  { label: "POS Type", key: "posType" },
  { label: "ABN", key: "abn" },
  { label: "Address / Distributor Name", key: "addressName" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Zip", key: "zip" },
  { label: "Qty", key: "qty" },
  { label: "Est. Cost", key: "estimatedCost" },
  { label: "Total Beacon Cost", key: "totalBeaconCost" },
  { label: "Est. Tax", key: "estimatedTax" },
  { label: "Est. Freight", key: "estimatedFreight" },
  { label: "Est. Extended", key: "estimatedExtendedCost" },
  { label: "Actual Cost", key: "actualCost" },
  { label: "Actual Tax", key: "actualTax" },
  { label: "Actual Freight", key: "actualFreight" },
  { label: "Actual Extended", key: "actualExtenededCost" },
  { label: "Order Date", key: "orderDate" },
  { label: "In Market Date", key: "inMarketDate" },
  { label: "Estimated Ship Date", key: "estShipDate" },
  { label: "Ship Date", key: "shipDate" },
  { label: "Status", key: "status" },
  { label: "Compliance Status", key: "complianceStatus" },
  { label: "Supplier", key: "suppler" },
  { label: "Order Number", key: "orderNumber" },
  { label: "Invoice Number", key: "invoiceNumber" },
  { label: "Internal Order", key: "internalOrder" },
  { label: "Anaplan Codes", key: "anaplanSubProgramCodes" },
];

export const openPurchaseOrdersHeaders = [
  { label: "Sequence #", key: "itemNumber" },
  { label: "Brand", key: "brand" },
  { label: "Item Type", key: "itemType" },
  { label: "In Market Date", key: "inMarketDate" },
  { label: "Status", key: "status" },
  { label: "Purchase Order #", key: "poId" },
  { label: "Supplier", key: "supplier" },
  { label: "Qty", key: "qty" },
  { label: "Actual Cost", key: "actCost" },
  { label: "Submitted At", key: "submittedAt" },
];

export const variantTerritoryHeaders = [
  { label: "Sequence #", key: "itemNumber" },
  { label: "Item Description", key: "description" },
  { label: "Item Type", key: "itemType" },
  { label: "Brands", key: "brands" },
  { label: "Territory", key: "territory" },
  { label: "Allocated Qty", key: "allocatedQty" },
  { label: "Ordered Qty", key: "orderedQty" },
  { label: "Pending Qty", key: "pendingQty" },
  { label: "Available", key: "available" },
  { label: "Expected Date", key: "expectedDate" },
];

export const purchaseOrderReportHeaders = [
  { label: "PO Number", key: "poId" },
  { label: "Purchaser", key: "purchaser" },
  { label: "Supplier", key: "supplier" },
  { label: "Sequence #", key: "itemNumber" },
  { label: "Brand(s)", key: "brands" },
  { label: "Item Code", key: "itemCode" },
  { label: "Item Type", key: "itemType" },
  { label: "Order Qty", key: "qty" },
  { label: "Act. Cost/Unit", key: "actualCost" },
  { label: "Extended Cost", key: "extendedCost" },
  { label: "Submitted Date", key: "submittedAt" },
  { label: "In Market Date", key: "inMarketDate" },
  { label: "Allocated", key: "allocation" },
  { label: "PO Status", key: "status" },
  { label: "Invoice Number", key: "invoiceNumber" },
  { label: "Freight Costs", key: "freightCost" },
  {
    label: "Additional Freight Invoice #",
    key: "additionalFreightInvoiceNumber",
  },
  { label: "Additional Freight Costs", key: "additionalFreightCosts" },
  {
    label: "Broker Freight Invoice #",
    key: "brokerFreightInvoiceNumber",
  },
  { label: "Broker Freight Costs", key: "brokerFreightCosts" },
  { label: "Beacon", key: "includeBeacon" },
];
