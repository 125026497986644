import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";
import Users from "src/components/Settings/Users";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import { ItemTypesAdmin } from "@features/itemTypes";

import AnaplanTroubleshooting from "../components/Settings/AnaplanTroubleshooting";
import Brands from "../components/Settings/Brands";
import OrderCalendarMonths from "../components/Settings/OrderCalendarMonths";
import Suppliers from "../components/Settings/Suppliers";
import Territories from "../components/Settings/Territories";
import VariablePricing from "../components/Settings/VariablePricing";
import OrderPatchLoading from "../components/Utility/OrderPatchLoading";
import { useWindowHash } from "../hooks/UtilityHooks";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  borderRight: {
    paddingRight: "10px",
    borderRight: "1px solid #4C4C4C",
    minWidth: "150px",
  },
}));

const Settings = ({ userType }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const tabs = [
    {
      hash: "#users",
      label: "Users",
      component: <Users />,
      permittedUserRoles: ["super"],
    },
    {
      hash: "#territories",
      label: "Territories",
      component: <Territories />,
      permittedUserRoles: ["super"],
    },
    {
      hash: "#brands",
      label: "Brands",
      component: <Brands />,
      permittedUserRoles: ["super"],
    },
    {
      hash: "#itemTypes",
      label: "Item Types",
      component: <ItemTypesAdmin />,
      permittedUserRoles: ["super"],
    },
    {
      hash: "#orderCalendarMonths",
      label: "Order Calendar Months",
      component: <OrderCalendarMonths />,
      permittedUserRoles: ["super", "purchaser"],
    },
    {
      hash: "#suppliers",
      label: "Suppliers",
      component: <Suppliers />,
      permittedUserRoles: ["super", "purchaser"],
    },
    {
      hash: "#variablePricing",
      label: "Variable Pricing",
      component: <VariablePricing />,
      permittedUserRoles: ["super"],
    },
    {
      hash: "#anaplanTroubleshooting",
      label: "Anaplan Troubleshooting",
      component: <AnaplanTroubleshooting />,
      permittedUserRoles: ["super"],
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChangeTab = useWindowHash(
    tabs.map((t) => t.hash),
    setSelectedIndex
  );

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>RTA | Settings</title>
      </Helmet>
      <Container className={classes.mainWrapper}>
        <Grid container spacing={2} alignItems="stretch" wrap="nowrap">
          <Grid item md={1} xs={false} />
          <Grid item md={2} xs={2} className={classes.borderRight}>
            <List component="nav" aria-label="settings options">
              {tabs.map((tab, index) => {
                return (
                  tab.permittedUserRoles.includes(userType) && (
                    <div key={index}>
                      <Divider />
                      <ListItem
                        button
                        onClick={(evt) => {
                          handleChangeTab(evt, index);
                        }}
                      >
                        <ListItemText primary={tab.label} />
                      </ListItem>
                    </div>
                  )
                );
              })}
            </List>
          </Grid>
          <Grid item md={8} xs={10} style={{ paddingLeft: "20px" }}>
            {tabs.map((tab, index) => (
              <div key={index}>{selectedIndex === index && tab.component}</div>
            ))}
          </Grid>
          <Grid item md={1} xs={false} />
        </Grid>
      </Container>
      <br />
      <OrderPatchLoading />
    </>
  );
};

Settings.propTypes = {
  userType: PropTypes.string,
};

export default Settings;
