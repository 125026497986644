import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { handleErrors } from "../../../api/apiFunctions";
import { axiosGet, axiosGetNoFormat } from "../../../api/axiosCalls";

const initialState = {
  isLoading: false,
  data: [],
  dataExport: [],
  importStatus: {
    status: null,
    errors: [],
  },
  errors: null,
};

const variablePricingSlice = createSlice({
  name: "variablePricing",
  initialState,
  reducers: {
    setIsLoading: function (state) {
      state.isLoading = true;
    },
    getVariablePricingExportSuccess: function (state, { payload }) {
      state.dataExport = payload;
      state.isLoading = false;
      state.errors = null;
    },
    getVariablePricingSuccess: function (state, { payload }) {
      state.data = payload;
      state.isLoading = false;
      state.errors = null;
    },
    setErrors: function (state, { payload }) {
      state.errors = payload;
      state.isLoading = false;
    },
    setImportErrors: function (state, { payload }) {
      state.importStatus = {
        status: "error",
        errors: [...payload],
      };
      state.isLoading = false;
    },
    setImportSuccess: function (state) {
      state.importStatus = {
        status: "success",
        errors: [],
      };
    },
    clearImportStatus: function (state) {
      state.importStatus = {
        status: null,
        errors: [],
      };
    },
    clearVariablePricingData: function (state) {
      state.data = [];
    },
    clearVariablePricingDataExport: function (state) {
      state.dataExport = [];
    },
  },
});

export const {
  getVariablePricingSuccess,
  getVariablePricingExportSuccess,
  setIsLoading,
  setErrors,
  setImportErrors,
  setImportSuccess,
  clearImportStatus,
  clearVariablePricingData,
  clearVariablePricingDataExport,
} = variablePricingSlice.actions;

export default variablePricingSlice.reducer;

export const fetchVariablePricingData =
  (itemId, hasSpecCode) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      if (hasSpecCode) {
        const res = await axiosGet(
          `/api/variable-pricing?filter[item-id]=${itemId}`
        );
        const data = res.data.map((varPrice) => ({
          id: varPrice.id,
          stdSpecCode: varPrice["standard-specification-code"],
          maxValue: varPrice["max-range-value"],
          minValue: varPrice["min-range-value"],
          suppliers: varPrice["suppliers"],
          unitPrice: varPrice["unit-price"],
        }));
        dispatch(getVariablePricingSuccess(data));
      } else {
        let data = [];
        // Get item programs for an item.
        const itemProgsWithVarPricingRes = await axiosGet(
          `/api/item-programs?filter[item-id]=${itemId}&filter[has-variable-pricing]=true`
        );
        if (itemProgsWithVarPricingRes.data.length > 0) {
          // Get item programs for an item.
          const varPricing = await axiosGet(
            `/api/item-programs/${itemProgsWithVarPricingRes.data[0].id}/variable-pricing`
          );
          data = varPricing.data.map((varPrice) => ({
            id: varPrice.id,
            maxValue: varPrice["max-range-value"],
            minValue: varPrice["min-range-value"],
            suppliers: varPrice["suppliers"],
            unitPrice: varPrice["unit-price"],
          }));
        }
        dispatch(getVariablePricingSuccess(data));
      }
    } catch (error) {
      console.log(error);
      dispatch(setErrors("Error getting variable pricing info"));
    }
  };

export const fetchVariablePricingDataExport =
  (suppliers) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      const res = await axiosGetNoFormat(
        `/api/variable-pricing/export?filter[suppliers]=${suppliers}`
      );
      dispatch(getVariablePricingExportSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(setErrors(error));
    }
  };

export const uploadVariablePricing = (file) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const formData = new FormData();
    formData.append("csv", file);
    await axios.post("/api/variable-pricing/import", formData);
    dispatch(setImportSuccess());
  } catch (error) {
    const handled = handleErrors(error);
    if (handled?.errors) {
      dispatch(setImportErrors(handled.errors));
    } else {
      dispatch(setImportErrors([handled]));
    }
  }
};
