export enum VariantCategoryName {
  "Sports" = "Sports",
  "Sizes" = "Size",
}

export interface VariantCategory {
  id: string;
  name: VariantCategoryName;
}

export interface VariantOption {
  id: string;
  name: string;
  abbreviation: string;
  variantCategory: VariantCategory;
}
