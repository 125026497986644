import { Link } from "react-router-dom";

export const getLinkProps = (link: string | undefined) => {
  if (!link) {
    return { component: 'button' };
  }

  return {
    component: Link,
    to: link,
    ...(link.startsWith('http') && {
      target: '_blank',
      rel: 'noopener noreferrer',
    }),
  };
}; 