import { compareDesc, isBefore } from "date-fns";

import { brandLogoMap } from "../../../utility/constants";
import { formatDateString, utcDate } from "../../../utility/utilityFunctions";

const monthMap = {
  1: "January",
  "01": "January",
  2: "February",
  "02": "February",
  3: "March",
  "03": "March",
  4: "April",
  "04": "April",
  5: "May",
  "05": "May",
  6: "June",
  "06": "June",
  7: "July",
  "07": "July",
  8: "August",
  "08": "August",
  9: "September",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

export const mapPrograms = (programs, active) => {
  const now = new Date();
  let programArray = programs.map((prog) => {
    // Remove any weird characters
    const cleanedFirstBrand = prog.brands[0]?.name
      .replace(/[^a-z\-&\s]/gi, "")
      .replace(/\s+/g, " ");

    const visibleOcmKey = active ? "order-window-open-date" : "visible-date";

    // Find the most recent OCM whose visibleOcmKey date is in the past
    const selectedOcm = [...prog["order-calendar-months"]]
      .sort((a, b) =>
        compareDesc(new Date(a[visibleOcmKey]), new Date(b[visibleOcmKey]))
      )
      .find((ocm) => isBefore(utcDate(ocm[visibleOcmKey]), now));

    return {
      id: prog.id,
      orderMonth: selectedOcm
        ? monthMap[selectedOcm["purchasing-close-date"].split("-")[1]]
        : "",
      orderMonthId: selectedOcm?.id,
      isAdHoc: prog["is-ad-hoc"],
      type: prog.type,
      name: prog.name,
      brand:
        prog.brands.length === 0
          ? ["Multi-brand"]
          : prog.brands.map((brand) => brand.name),
      primaryBrandName: prog["primary-brand"]?.name || "",
      unit: prog["business-unit-names"][0],
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec fringilla arcu vitae nunc rhoncus, condimentum auctor tellus ullamcorper. Nullam felis enim, hendrerit nec egestas non, convallis quis orci. Ut non maximus risus, in tempus felis. Morbi euismod blandit bibendum. Suspendisse pulvinar elit porta imperdiet porta. Pellentesque eu rhoncus lectus. Morbi ultrices molestie nisi id ultrices.",
      goals: prog.goals,
      strategies: prog.strategies,
      startDate: prog["start-date"] ? prog["start-date"] : "---",
      endDate: prog["end-date"] ? prog["end-date"] : "---",
      orderWindow: selectedOcm?.["order-window-name"]
        ? selectedOcm["order-window-name"]
        : "---",
      purchasingClose: selectedOcm?.["purchasing-close-date"],
      inMarketDate: prog["start-date"]
        ? formatDateString(prog["start-date"])
        : "---",
      orderWindowOpen: selectedOcm
        ? formatDateString(selectedOcm["order-window-open-date"])
        : null,
      orderWindowClose: selectedOcm
        ? formatDateString(selectedOcm["order-window-close-date"])
        : null,
      orderWindowVisible: selectedOcm
        ? formatDateString(selectedOcm["visible-date"])
        : null,
      focusMonth: prog["start-date"]
        ? monthMap[prog["start-date"].split("-")[1]]
        : "---",
      imgUrl: brandLogoMap[cleanedFirstBrand]
        ? brandLogoMap[cleanedFirstBrand]
        : "https://res.cloudinary.com/brandhub/image/upload/v1612303566/prod/BrandLogos/Logo_EJG_Crest_uatj0x.png",
      items: [],
      isItemsFetched: false,
      status: false,
      anaplanProgramCodes: prog["anaplan-program-codes"],
    };
  });
  return programArray;
};
