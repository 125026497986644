/** @jsxImportSource @emotion/react */
import tw, { css, styled } from "twin.macro";

import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { InputAdornment } from "@mui/material";

import { isFuture } from "date-fns";
import _ from "lodash";

import ControlledBrandAutocomplete from "@utils/forms/ControlledBrandAutocomplete";

import {
  useCouponTypes,
  useOrderCalendarMonthOptions,
  useProgramInfo,
} from "../../../hooks/planningToolHooks";
import { expandItemProgram } from "../../../redux/slices/planningTool/itemProgramSlice";
import {
  intValidation,
  moneyAdornment,
  moneyValidation,
  numberValidation,
} from "../../../utility/inputHelpers";
import { formatDate } from "../../../utility/utilityFunctions";
import {
  CheckboxInput,
  DateInput,
  SelectInput,
  TextInput,
} from "../../Forms/ControlledInputs";
import { StyledButton } from "../../StyledComponents";
import CouponQuantityModal from "./CouponQuantityModal";
import FormItemTypeSelector from "./FormItemTypeSelector";

const designTypeOptions = [
  { label: "EPR", id: "epr" },
  { label: "CPR", id: "cpr" },
];
const complexityScoreOptions = [
  { label: "Low", id: "low" },
  { label: "Medium", id: "medium" },
  { label: "High", id: "high" },
];

const FormRow = styled.div(
  css(tw`flex items-start justify-start gap-x-4`, {
    "& > *": tw`flex-1`,
  })
);

const CouponForm = ({ form, controlled, item, clonedFromItemProgramId }) => {
  const { itemId, id: itemProgramId, orderCalendarMonth } = item || {};
  const isTemplatedCoupon = item?.itemProgramType === "templatedCoupon";

  const dispatch = useDispatch();
  const {
    brandAssignmentLevel,
    orderCalendarMonth: programOrderCalendarMonth,
    requiresOCM,
  } = useProgramInfo();

  const { watch, setValue } = form;

  const [couponTypeId, couponIssueDate, couponOrderStartDate] = watch([
    "couponTypeId",
    "couponIssueDate",
    "couponOrderStartDate",
  ]);

  const couponTypeOptions = useCouponTypes() as any[];

  const couponType = useMemo(
    () => couponTypeOptions.find(({ id }) => id === couponTypeId) ?? null,
    [couponTypeOptions, couponTypeId]
  );

  const [qtyModalOpen, setQtyModalOpen] = useState(false);

  const ocmOptions = useOrderCalendarMonthOptions(
    orderCalendarMonth || programOrderCalendarMonth
  );

  const handleGoToMaster = () => {
    dispatch(expandItemProgram(clonedFromItemProgramId));
  };

  const handleChangeItemType = (selectedItemType) => {
    // Handle set defaults from item Type
    Object.entries(
      _.pick(selectedItemType, [
        "qtyPerPack",
        "sendToContentHub",
        "rtaDeployment",
      ])
    ).forEach(([key, value]) => value !== null && setValue(key, value));

    // fetch subTypes
    const { itemSubTypes } = selectedItemType;
    setValue(
      "itemSubTypeId",
      itemSubTypes.find(({ label }) => label === "Default")?.id
    );
    const { estimatedCost, /* leadTimeInDays, */ qtyPerPack, complexityScore } =
      itemSubTypes[0];
    Object.entries({
      complexityScore: complexityScore.toLowerCase(),
      estimatedCost,
      qtyPerPack,
    }).forEach(([key, value]) => value && setValue(key, String(value)));
  };

  const setEstimatedRedemptionDefault = (couponTypeId) => {
    const couponType = couponTypeOptions.find(({ id }) => id === couponTypeId);
    const defaultEstRedemptions = {
      MIR: 3,
      INS: 26,
      MBG: 6,
    }[couponType?.typeCode ?? ""];
    if (defaultEstRedemptions) {
      setValue("couponEstimatedRedemption", defaultEstRedemptions);
    }
  };

  return (
    <>
      <div tw="space-y-4">
        <FormRow>
          <FormItemTypeSelector
            {...controlled("itemTypeId", { required: true })}
            onChange={handleChangeItemType}
            isCoupon
          />
          <TextInput label="Description" {...controlled("comment")} />
          <SelectInput
            label="Complexity Score"
            options={complexityScoreOptions}
            {...controlled("complexityScore")}
          />
        </FormRow>
        <FormRow>
          <TextInput
            label="Est Cost"
            InputProps={moneyAdornment}
            {...controlled("estimatedCost", moneyValidation)}
          />
          <TextInput
            label="Pack Size"
            {...controlled("qtyPerPack", intValidation)}
          />
          <TextInput label="MOQ" {...controlled("moq", intValidation)} />
          <DateInput
            label="Remove from RTA date"
            disablePast
            {...controlled("removeFromCatalogDate", {
              validate: (v) => isFuture(new Date(v)),
            })}
          />
          <TextInput
            label="Art Reference Number"
            {...controlled("artReferenceNumber")}
          />
        </FormRow>
        <FormRow>
          {!isTemplatedCoupon && (
            <div tw="grow flex flex-col xl:flex-row gap-4">
              <TextInput
                label="Creative Instructions"
                multiline
                fullWidth
                minRows={2}
                maxRows={5}
                {...controlled("creativeInstructions")}
              />
              <TextInput
                label="Other Notes"
                multiline
                fullWidth
                minRows={2}
                maxRows={5}
                {...controlled("otherNotes")}
              />
            </div>
          )}

          <div
            css={[
              tw`flex flex-wrap gap-x-3`,
              !isTemplatedCoupon &&
                tw`grid flex-none md:grid-cols-2 xl:grid-cols-3 gap-x-3`,
            ]}
          >
            <CheckboxInput label="Active" {...controlled("isActive")} />
            <CheckboxInput
              label="Deploy to RTA?"
              {...controlled("rtaDeployment")}
            />
            <CheckboxInput
              label="Content Hub?"
              {...controlled("sendToContentHub")}
            />
            <CheckboxInput
              label="Is Sweepstakes?"
              {...controlled("isSweepstakes")}
            />
            <CheckboxInput
              label="Is Digital Coupon?"
              {...controlled("isDigitalCoupon")}
            />
          </div>
        </FormRow>
        <div tw="-mx-3 px-3 -my-4 py-4 bg-sky-50 space-y-4 rounded-lg border-2 border-solid border-sky-100">
          <div tw="flex justify-between items-baseline text-base text-sky-900 -mt-2 ">
            <div tw="">Coupon offer details</div>
            {clonedFromItemProgramId ? (
              <div>
                <span tw="text-sm">cloned</span>
                <button
                  tw="py-0 px-2 ml-2 rounded bg-sky-100 text-sky-900 text-sm cursor-pointer border-none hover:bg-slate-200"
                  onClick={handleGoToMaster}
                >
                  go to master
                </button>
              </div>
            ) : (
              <div tw="py-0 px-2 rounded bg-sky-100 text-sm">original</div>
            )}
          </div>
          <FormRow>
            <SelectInput
              label="Coupon Type"
              options={couponTypeOptions}
              {...controlled("couponTypeId")}
              onChange={(e) => setEstimatedRedemptionDefault(e.target.value)}
            />
            {brandAssignmentLevel === "item" && (
              <ControlledBrandAutocomplete
                label="Brand"
                limitTags={1}
                multiple
                {...controlled("brands", { required: true })}
              />
            )}
            {couponType?.typeCode === "INS" && (
              <TextInput
                tw="flex-[1.5]!"
                label="Purchase Requirement Code"
                {...controlled("couponPurchaseRequirementCode")}
              />
            )}
            {couponType?.typeCode === "MIR" && (
              <TextInput
                label="Total Value"
                placeholder="0.0"
                {...controlled("couponTotalValueMir", numberValidation)}
              />
            )}
            <SelectInput
              label="Design Type"
              options={designTypeOptions}
              {...controlled("designType")}
            />

            {requiresOCM && (
              <SelectInput
                tw="grow"
                label="Order Window Month"
                options={ocmOptions}
                {...controlled("orderCalendarMonthId")}
              />
            )}
          </FormRow>
          <FormRow>
            <TextInput
              tw="grow-[1.5]"
              label="Coupon Offer"
              {...controlled("couponOfferDescription")}
            />
            <TextInput
              label="Offer amount"
              placeholder="0.0"
              {...controlled("couponFaceValue", numberValidation)}
            />
            <TextInput
              label="Est. Redemption"
              placeholder="0.0%"
              helperText="As a percentage (0 - 100%)"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              {...controlled("couponEstimatedRedemption", {
                ...numberValidation,
                max: 100,
              })}
            />
            {itemProgramId && (
              <div tw="flex">
                <StyledButton
                  cta
                  tw="rounded-r-none whitespace-nowrap bg-slate-400"
                  onClick={() => setQtyModalOpen(true)}
                  disabled={controlled("estimatedQty").disabled}
                >
                  qty
                </StyledButton>
                <TextInput
                  tw="flex-1"
                  css={{
                    ".MuiOutlinedInput-root": tw`rounded-l-none`,
                  }}
                  label="Est. Qty"
                  {...controlled("estimatedQty")}
                  disabled
                />
              </div>
            )}
          </FormRow>
          <FormRow>
            <DateInput
              label="Orderability Start Date"
              {...controlled("couponOrderStartDate")}
            />
            <DateInput
              label="Orderability End Date"
              minDate={formatDate(couponOrderStartDate)}
              minDateMessage="Cannot be before orderability start date"
              {...controlled("couponOrderEndDate")}
            />
            <DateInput
              label="Offer Start Date"
              {...controlled("couponIssueDate")}
            />
            <DateInput
              label="Offer Expiration Date"
              minDate={formatDate(couponIssueDate)}
              minDateMessage="Cannot be before start date"
              {...controlled("couponExpirationDate")}
            />
          </FormRow>
        </div>

        <CouponQuantityModal
          open={qtyModalOpen}
          handleClose={() => setQtyModalOpen(false)}
          itemId={itemId}
          itemProgramId={itemProgramId}
        />
      </div>
    </>
  );
};

export default CouponForm;
