import { useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Order } from "@models/Order";
import { OrderSet, OrderSetType } from "@models/OrderSet";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const orderSetsKeyFactory = createQueryKeys("order-sets", {
  detail: (orderSetId) => ({
    queryKey: [orderSetId],
    queryFn: () =>
      client.get<OrderSet>(`order-sets/${orderSetId}`).then((res) => res!.data),
    contextQueries: {
      orders: {
        queryKey: null, // ['order-sets', 'detail', orderSetId, 'orders']
        queryFn: () =>
          client
            .get<Order[]>("orders", {
              params: { filter: { orderSetId }, skipPagination: true },
            })
            .then((res) => res.data),
      },
    },
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<OrderSet[]>("order-sets", { params }),
  }),
});

export const useOrderSetsPaginated = buildPaginatedQuery(
  orderSetsKeyFactory.paginated
);

type CreateOrderSetPayload = {
  type: OrderSetType;
  orderWindowId?: string;
  programId?: string;
  sportsVariantOptionIds?: (string | number)[];
};

export const useCreateOrderSetMutation = () => {
  const queryClient = useQueryClient();
  const { currentChannel: channel, currentTerritory: territory } = useSelector(
    (state: any) => state.user
  );
  return useMutation({
    mutationFn: ({
      orderWindowId,
      programId,
      sportsVariantOptionIds,
      ...data
    }: CreateOrderSetPayload) =>
      client
        .post<OrderSet>("order-sets", {
          __type: "order-set",
          channel,
          ...(territory
            ? {
                territory: {
                  type: "territory",
                  id: territory,
                },
                allRegions: false,
              }
            : { allRegions: true }),
          ...(programId && {
            program: {
              id: programId,
            },
          }),
          ...(orderWindowId && {
            orderCalendarMonth: {
              id: orderWindowId,
            },
          }),
          sportsVariantOptionIds: sportsVariantOptionIds?.map(Number) ?? [],
          ...data,
          relationshipNames: ["program", "orderCalendarMonth", "territory"],
        })
        .then((res) => res.data),
    onSuccess: (orderSet) => {
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.paginated._def,
      });
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSet.id).queryKey,
        orderSet
      );
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSet.id)._ctx.orders.queryKey,
        []
      );
    },
  });
};
