export const financeTrackerHeaders = [
  { label: "Ordered By", id: "user" },
  { label: "Brand", id: "brandCode" },
  { label: "Market", id: "territory" },
  { label: "Cost to Drop In", id: "dropCost" },
  { label: "Month in Market", id: "inMarketMonth" },
  { label: "", id: "blankA" },
  { label: "Item Type", id: "itemType" },
  { label: "", id: "blankB" },
  { label: "", id: "blankC" },
  { label: "Qty Ordered", id: "totalQty" },
  { label: "Seq #", id: "itemNumber" },
  { label: "Program", id: "program" },
  { label: "BU", id: "unit" },
  { label: "Estimated Cost", id: "estCost" },
  { label: "Actual Cost", id: "actCost" },
  { label: "Order Type", id: "orderType" },
  { label: "State", id: "state" },
  { label: "Brand Funded", id: "brandFunded" },
  { label: "Coupon Redemption Value", id: "couponValue" },
];

export const focusSummaryHeaders = [
  { label: "Supplier", id: "supplier" },
  { label: "Category", id: "programType" },
  { label: "Brands", id: "brands" },
  { label: "Business Units", id: "bus" },
  { label: "Program", id: "program" },
  { label: "Seq #", id: "itemNumber" },
  { label: "Item Type Code", id: "itemTypeCode" },
  { label: "Item Type", id: "itemType" },
  { label: "Item Description", id: "itemDesc" },
  { label: "Total Quantity", id: "totalQty" },
  { label: "Est. Cost", id: "estCost" },
  { label: "Total Est. Cost", id: "totalEstCost" },
];

export const rfpSummaryHeaders = [
  { label: "RFP #", id: "id" },
  { label: "Supplier", id: "supplier" },
  { label: "Category", id: "programType" },
  { label: "Brands", id: "brands" },
  { label: "Business Units", id: "bus" },
  { label: "Program", id: "program" },
  { label: "Seq #", id: "itemNumber" },
  { label: "Item Type Code", id: "itemTypeCode" },
  { label: "Item Type", id: "itemType" },
  { label: "Item Description", id: "itemDesc" },
  { label: "Total Quantity", id: "totalQty" },
  { label: "Est. Cost", id: "estCost" },
  { label: "Bids", id: "bids" },
  { label: "Bids with 5% Markup", id: "bidsWithMarkup" },
  { label: "Bid Statuses", id: "bidStatuses" },
];

export const focusDetailHeaders = [
  { label: "Seq #", id: "itemNumber" },
  { label: "Programs", id: "program" },
  { label: "Brands", id: "brands" },
  { label: "Business Units", id: "bus" },
  { label: "Territory", id: "territory" },
  { label: "ABN", id: "addressABN" },
  { label: "Distributor / Name", id: "addressName" },
  { label: "Address One", id: "addressOne" },
  { label: "Address Two", id: "addressTwo" },
  { label: "City", id: "city" },
  { label: "State", id: "state" },
  { label: "Zip", id: "zip" },
  { label: "Tactic", id: "isCoupon" },
  { label: "Item Type Code", id: "itemTypeCode" },
  { label: "Item Type", id: "itemType" },
  { label: "Item Description", id: "itemDesc" },
  { label: "Supplier", id: "supplier" },
  { label: "Total Quantity", id: "totalQty" },
  { label: "Est. Cost", id: "estCost" },
  { label: "Total Est. Cost", id: "totalEstCost" },
  { label: "Extended Cost w/ Est. Tax", id: "totalEstWithTax" },
  { label: "Ordered By", id: "user" },
  { label: "Status", id: "status" },
];

export const poComplianceHeaders = [
  { label: "PO #", id: "poId" },
  { label: "Ordered By", id: "orderedBy" },
  { label: "Purchaser", id: "purchaser" },
  { label: "Supplier", id: "supplier" },
  { label: "Seq #", id: "itemNumber" },
  { label: "Programs", id: "program" },
  { label: "Brand Codes", id: "brandCode" },
  { label: "Brands", id: "brand" },
  { label: "Item Type Code", id: "itemTypeCode" },
  { label: "Item Type", id: "itemType" },
  { label: "Rule Type", id: "ruleType" },
  { label: "Rule Description", id: "ruleDesc" },
  { label: "Rule Status", id: "status" },
  { label: "State", id: "state" },
  { label: "Territory", id: "territory" },
  { label: "In Market Date", id: "inMarketDate" },
  { label: "Order Type", id: "orderType" },
  { label: "Email Sent", id: "emailSent" },
  { label: "Email Sent Date", id: "emailSentDate" },
];

export const inventoryHeaders = [
  { label: "Seq #", id: "itemNumber" },
  { label: "Warehouse", id: "warehouse" },
  { label: "Programs", id: "program" },
  { label: "Brands", id: "brand" },
  { label: "Brand Codes", id: "brandCode" },
  { label: "Business Units", id: "bu" },
  { label: "Item Type Code", id: "itemTypeCode" },
  { label: "Item Type", id: "itemType" },
  { label: "Item Description", id: "itemDesc" },
  { label: "Allocation Type", id: "allocationType" },
  { label: "Allocation Name", id: "allocationName" },
  { label: "Region", id: "region" },
  { label: "Available to order", id: "availableToOrderQty" },
  { label: "Est. Cost / Unit", id: "estCost" },
  { label: "Last Recieved Qty.", id: "mostRecentReceivedQty" },
  { label: "Last Received Date", id: "mostRecentReceivedDate" },
  { label: "Pending Ordered Qty", id: "pendingOrdered" },
  { label: "First Ship", id: "firstShip" },
  { label: "Last Ship", id: "lastShip" },
  { label: "90 Day Order Qty", id: "ninetyDayQty" },
  { label: "Year Order Qty", id: "yearQty" },
];

export const availableInventoryHeaders = [
  { label: "Seq #", id: "itemNumber" },
  { label: "Brands", id: "brand" },
  { label: "Brand Codes", id: "brandCode" },
  { label: "Business Units", id: "bu" },
  { label: "Item Type", id: "itemType" },
  { label: "Item Description", id: "itemDesc" },
  { label: "Allocation Type", id: "allocationType" },
  { label: "Allocation Name", id: "allocationName" },
  { label: "Available to Order", id: "availableToOrderQty" },
  { label: "Est. Cost / Unit", id: "estCost" },
];

export const roadmapForecastHeaders = [
  { label: "Order Type", id: "orderType" },
  { label: "Order Number", id: "orderNumber" },
  { label: "12 Digit Code", id: "anaplanCode" },
  { label: "Territory", id: "territory" },
  { label: "Brand", id: "brand" },
  { label: "Ordered By", id: "user" },
  { label: "Brand Code", id: "brandCode" },
  { label: "State", id: "state" },
  { label: "Total Cost", id: "totalCost" },
  { label: "In Market Date", id: "inMarketDate" },
  { label: "Tactic", id: "isCoupon" },
  { label: "Item Type", id: "itemType" },
  { label: "", id: "blank_1" },
  { label: "", id: "blank_2" },
  { label: "Total Qty", id: "qty" },
  { label: "Seq. #", id: "itemNumber" },
  { label: "Program", id: "program" },
  { label: "Item Description", id: "itemDesc" },
  { label: "Distributor / Address Name", id: "name" },
  { label: "Est. Cost / Unit", id: "estCost" },
  { label: "Act. Cost / Unit", id: "actCost" },
  { label: "Order Date", id: "orderDate" },
  { label: "Ship Date", id: "shipDate" },
  { label: "Carrier", id: "carrier" },
  { label: "Tracking #", id: "trackingNumber" },
  { label: "Status", id: "orderStatus" },
];

export const customCouponHeaders = [
  { label: "Date Created", id: "createdAt" },
  { label: "Created By", id: "createdBy" },
  { label: "Ordered By", id: "userName" },
  { label: "Email", id: "userEmail" },
  { label: "Cart Type", id: "territoryType" },
  { label: "Territory", id: "territoryName" },
  { label: "Brand Code(s)", id: "brandCode" },
  { label: "Brand Name(s)", id: "brand" },
  { label: "Seq #", id: "itemNumber" },
  { label: "Barcode Id", id: "barcodeId" },
  { label: "Offer Type", id: "couponOfferTypeCode" },
  { label: "Version", id: "couponTypeCode" },
  { label: "Item Type Code", id: "itemTypeCode" },
  { label: "Item Type", id: "itemType" },
  { label: "Start Date", id: "couponStartDate" },
  { label: "Expiration Date", id: "couponExpirationDate" },
  { label: "Face Value", id: "couponFaceValue" },
  { label: "NAB Item", id: "isNABItem" },
  { label: "andType", id: "andType" },
  { label: "Offer Detail", id: "couponOfferDetail" },
  { label: "Total Qty", id: "totalQty" },
  { label: "State/Territory Qty", id: "stateTerrQty" },
  { label: "Shipping State", id: "state" },
  { label: "Brand Funded", id: "brandFunded" },
  { label: "Channel", id: "channel" },
  { label: "Program Type", id: "programType" },
];

export const cdcSummaryHeaders = [
  { label: "Supplier", id: "supplier" },
  { label: "Brand(s)", id: "brand" },
  { label: "Seq. #", id: "itemNumber" },
  { label: "Item Type", id: "itemType" },
  { label: "Total Quantity", id: "totalQty" },
  { label: "Comments", id: "comment" },
];

export const cdcShipListHeaders = [
  { label: "Param Item Id", id: "id" },
  { label: "Purchase Order #", id: "purchaseOrderNum" },
  { label: "Supplier Reference", id: "supplierReference" },
  { label: "Key Account", id: "isKeyAccount" },
  { label: "Key Account Name", id: "territory" },
  { label: "Delivery Date", id: "inMarketDate" },
  { label: "Order Notes", id: "orderNotes" },
  { label: "ABN", id: "distributorAbn" },
  { label: "Distributor / Name", id: "addressName" },
  { label: "Address 1", id: "addressOne" },
  { label: "Address 2", id: "addressTwo" },
  { label: "Attention", id: "attn" },
  { label: "City", id: "city" },
  { label: "State", id: "state" },
  { label: "Zip", id: "zip" },
  { label: "Phone Number", id: "phoneNumber" },
  { label: "Item Number", id: "itemNumber" },
  { label: "Labeling Info", id: "shippingLabel" },
  { label: "Total Quantity", id: "totalQty" },
  { label: "Order Approval Status", id: "complianceStatus" },
  { label: "Carrier", id: "carrier" },
  { label: "Ship Method", id: "method" },
  { label: "Actual Ship Date", id: "actShipDate" },
  { label: "Shipped Quantity", id: "shippedQty" },
  { label: "Package Count", id: "packageCount" },
  { label: "Tracking Number", id: "tracking" },
  { label: "Tax", id: "tax" },
];

export const cdcTransferAdviceHeaders = [
  { label: "Payload ID", id: "payloadId" },
  { label: "TimeStamp", id: "timeStamp" },
  { label: "PO NUMBER (Order ID)", id: "purchaseOrderNum" },
  { label: "Order Date", id: "nowDate" },
  { label: "Order Type", id: "orderType" },
  { label: "Total Money", id: "totalMoney" },
  { label: "Ship To Address ID (ABN)", id: "abn" },
  { label: "Ship To Name", id: "shipName" },
  { label: "Ship To Deliver To", id: "shipDeliverTo" },
  { label: "Ship To Street 1", id: "shipStreetOne" },
  { label: "Ship To Street 2", id: "shipStreetTwo" },
  { label: "Ship To City", id: "shipCity" },
  { label: "Ship To State", id: "shipState" },
  { label: "Ship To Postal", id: "shipZip" },
  { label: "Ship To Country", id: "shipCountry" },
  { label: "Bill To Name", id: "billName" },
  { label: "Bill To Deliver To", id: "billDeliverTo" },
  { label: "Bill To Street 1", id: "billStreetOne" },
  { label: "Bill To Street 2", id: "billStreetTwo" },
  { label: "Bill To City", id: "billCity" },
  { label: "Bill To State", id: "billState" },
  { label: "Bill To Postal", id: "billZip" },
  { label: "Bill To Country", id: "billCountry" },
  { label: "Shipping Money", id: "shipMoney" },
  { label: "Shipping Description", id: "shipDesc" },
  { label: "Shopper User Name", id: "shopperName" },
  { label: "Cost Center", id: "costCenter" },
  { label: "Request Type", id: "reqType" },
  { label: "Requested Ship Date", id: "inMarketDate" },
  { label: "Expected Arrival Date", id: "arrivalDate" },
  { label: "Producing Vendor", id: "supplier" },
  { label: "Vendor PO Number", id: "vendorNum" },
  { label: "Rapid Order Number", id: "rapidNum" },
  { label: "Date Time", id: "dateTime" },
  { label: "Payload ID", id: "payloadId2" },
  { label: "TimeStamp", id: "timeStamp2" },
  { label: "PO NUMBER (Order ID)", id: "purchaseOrderNum2" },
  { label: "Quantity", id: "totalQty" },
  { label: "Line Number", id: "lineNum" },
  { label: "Supplier Part Id", id: "itemNumber" },
  { label: "Unit Price", id: "unitPrice" },
  { label: "Description", id: "itemType" },
  { label: "Unit of Measure", id: "unitOfMeasure" },
  { label: "Classification", id: "classification" },
  { label: "Pack Size", id: "packSize" },
  { label: "Brand", id: "brand" },
  { label: "Brand Code", id: "brandCode" },
  { label: "Package Count", id: "packageCount" },
  { label: "Package Type", id: "packageType" },
  { label: "Tracking Number", id: "tracking" },
  { label: "Rapid Order Number", id: "rapidNum2" },
  { label: "Date Time", id: "dateTime2" },
];

export const supplierSummaryHeaders = [
  { label: "Supplier Name", id: "supplier" },
  { label: "Channel", id: "channel" },
  { label: "Total Purchase Orders", id: "totalPurchaseOrders" },
  { label: "Total Product Spend", id: "totalPOProductSpend" },
  { label: "Total Freight", id: "totalFreight" },
  { label: "Total Tax", id: "totalTax" },
  {
    label: "Total Purchase Order Amount (w/ Tax & Freight)",
    id: "totalPOAmount",
  },
];

export const supplierDetailHeaders = [
  { label: "Purchase Order #", id: "poId" },
  { label: "Supplier", id: "supplier" },
  { label: "Sequence #", id: "itemNumber" },
  { label: "Brand(s)", id: "brand" },
  { label: "Item Type", id: "itemType" },
  { label: "Quantity", id: "qty" },
  { label: "Cost / Unit", id: "actCost" },
  { label: "Total Cost", id: "totalCost" },
  { label: "Freight Cost", id: "shippingCost" },
  { label: "Tax", id: "tax" },
  { label: "Order Type", id: "orderType" },
  { label: "Program", id: "program" },
  { label: "Channel", id: "channel" },
  { label: "Carrier", id: "carrier" },
  { label: "Method", id: "method" },
  { label: "Setup Fee", id: "setUpFee" },
  { label: "PO Requested Date", id: "poRequestedDate" },
];

export const financeBillingHeaders = [
  { label: "GL Account", id: "glAccount" },
  { label: "Internal Order", id: "internalOrder" },
  { label: "Sequence #", id: "itemNumber" },
  { label: "Description", id: "itemType" },
  { label: "Territory", id: "state" },
  { label: "Invoice #", id: "invoiceNumber" },
  { label: "Quantity", id: "qty" },
  { label: "Total", id: "total" },
];

export const financeJournalHeaders = [
  { label: "GL Account", id: "glAccount" },
  { label: "Total", id: "total" },
  { label: "", id: "blankA" },
  { label: "", id: "blankB" },
  { label: "", id: "blankC" },
  { label: "", id: "blankD" },
  { label: "Internal Order", id: "internalOrder" },
  { label: "", id: "blankE" },
  { label: "Assignment", id: "state" },
  { label: "Item Text", id: "itemText" },
  { label: "", id: "blankF" },
  { label: "Quantity", id: "qty" },
  { label: "Base Unit", id: "baseUnit" },
  { label: "Supplier", id: "supplier" },
  { label: "Invoice #", id: "invoiceNumber" },
];

export const orderedItemsHeaders = [
  { label: "Sequence #", id: "itemNumber" },
  { label: "Brand(s)", id: "brand" },
  { label: "Item Type", id: "itemType" },
  { label: "Description", id: "itemDesc" },
  { label: "Order Type", id: "orderType" },
  { label: "Est. Cost", id: "estCost" },
  { label: "Total Qty", id: "totalQty" },
  { label: "Total Est. Cost", id: "totalEstCost" },
  { label: "Canceled Qty", id: "canceledQty" },
];

export const invoicingHeaders = [
  { label: "PO #", id: "poId" },
  { label: "PO Type", id: "orderType" },
  { label: "Sequence #", id: "itemNumber" },
  { label: "Supplier", id: "supplierName" },
  { label: "Brand", id: "brand" },
  { label: "Quantity", id: "qty" },
  { label: "Act. Cost/Unit", id: "actualCost" },
  { label: "Submitted Date", id: "submittedDate" },
  { label: "Ship Date", id: "shipDate" },
  { label: "Allocation", id: "allocation" },
];

export const invoiceDoubleCheckHeaders = [
  { label: "PO #", id: "poId" },
  { label: "Supplier", id: "supplier" },
  { label: "Sequence #", id: "itemNumber" },
  { label: "Brand", id: "brand" },
  { label: "Item Type", id: "itemType" },
  { label: "Actual Unit Cost", id: "actualCost" },
  { label: "Qty Ordered", id: "qty" },
  { label: "Extended Product Cost", id: "extendedProductCost" },
  { label: "Freight Total", id: "totalFreight" },
  { label: "Taxes Total", id: "totalTax" },
  { label: "Total", id: "totalCost" },
];

export const invoiceDetailHeaders = [
  { label: "Invoice #", id: "invoiceNumber" },
  { label: "Internal Order", id: "internalOrder" },
  { label: "Sequence #", id: "itemNumber" },
  { label: "Unit Cost", id: "actualCost" },
  { label: "Qty", id: "qty" },
  { label: "Extended Product Cost", id: "totalCost" },
  { label: "State Assignment", id: "stateAssignment" },
];

export const printInvoiceHeaders = [
  { label: "Posting Date", id: "postingDate" },
  { label: "Document Date", id: "documentDate" },
  { label: "Company Code", id: "companyCode" },
  { label: "Document Type", id: "documentType" },
  { label: "Currency", id: "currency" },
  {
    label: "Doc. Header Text (Key Element all associated records)",
    id: "headerText",
  },
  { label: "Reference (Key Element all associated records)", id: "reference" },
  { label: "Ledger Group", id: "ledgerGroup" },
  { label: "Reversal Date", id: "reversalDate" },
  { label: "Reversal Reason", id: "reversalReason" },
  { label: "Exchange Rates", id: "exchangeRates" },
  { label: "Translation Date", id: "translationDate" },
  { label: "Posting Key", id: "postingKey" },
  { label: "GL Account", id: "glAccount" },
  { label: "Amount in Document Currency", id: "cost" },
  { label: "Amount in Local Currency", id: "localCurrencyAmt" },
  { label: "Amount in Global Currency", id: "globalCurrencyAmt" },
  { label: "Cost Center", id: "costCenter" },
  { label: "Profit Center", id: "profitCenter" },
  { label: "Internal Order", id: "internalOrder" },
  { label: "Trading Partner", id: "tradingPartner" },
  { label: "Assignment", id: "state" },
  { label: "Item Text", id: "itemNumber" },
  { label: "CoCode", id: "coCode" },
  { label: "Quantity", id: "qty" },
  { label: "Base Unit of Measure", id: "unitOfMeasuer" },
  { label: "Tax on Sales/Purchases Code", id: "taxOnSales" },
  { label: "Tax Jurisdiction", id: "taxJurisdiction" },
  { label: "Bill-to Party", id: "billToParty" },
  { label: "Ship-to Party", id: "shipToParty" },
  { label: "Material Number", id: "materialNumber" },
  { label: "PLANT", id: "plant" },
  { label: "Profit_Ctr2", id: "profitCtr" },
  { label: "Channel", id: "channel" },
  { label: "Long Text", id: "longText" },
  { label: "BRAND", id: "brand" },
  { label: "REGION", id: "territoryName" },
  { label: "Gallo/Non-Gallo", id: "isGallo" },
  { label: "Division VIP", id: "divisionVIP" },
  { label: "Reference Key 1", id: "referenceKey" },
  { label: "Line Material", id: "lineMaterial" },
];

export const orderWindowItemsHeaders = [
  { label: "Sequence #", id: "itemNumber" },
  { label: "Program", id: "program" },
  { label: "Brands", id: "brand" },
  { label: "Item Type", id: "itemType" },
  { label: "Item Description", id: "itemDescription" },
  { label: "Est. Cost", id: "estCost" },
  { label: "Supplier", id: "supplier" },
  { label: "Order Window", id: "orderWindow" },
];

export const orderDetailHeaders = [
  { label: "Sequence Number", id: "itemNumber" },
  { label: "Order Window", id: "orderWindowName" },
  { label: "In Market Month", id: "inMarketMonth" },
  { label: "Quarter", id: "quarter" },
  { label: "PO #", id: "poNumber" },
  { label: "Order Type", id: "orderType" },
  { label: "Ordered By", id: "orderedBy" },
  { label: "Channel", id: "channel" },
  { label: "Territory", id: "territoryNames" },
  { label: "Brand", id: "brands" },
  { label: "Business Unit", id: "businessUnits" },
  { label: "Program Type", id: "programType" },
  { label: "Program", id: "program" },
  { label: "Item Type", id: "itemType" },
  { label: "Item Desc.", id: "itemDescription" },
  { label: "POS Type", id: "posType" },
  { label: "ABN", id: "abn" },
  { label: "Address / Distributor Name", id: "addressName" },
  { label: "City", id: "city" },
  { label: "State", id: "state" },
  { label: "Zip", id: "zip" },
  { label: "Qty", id: "qty" },
  { label: "Est. Cost", id: "estimatedCost" },
  { label: "Total Beacon Cost", id: "totalBeaconCost" },
  { label: "Est. Tax", id: "estimatedTax" },
  { label: "Est. Freight", id: "estimatedFreight" },
  { label: "Est. Extended", id: "estimatedExtendedCost" },
  { label: "Actual Cost", id: "actualCost" },
  { label: "Actual Tax", id: "actualTax" },
  { label: "Actual Freight", id: "actualFreight" },
  { label: "Actual Extended", id: "actualExtenededCost" },
  { label: "Order Date", id: "orderDate" },
  { label: "In Market Date", id: "inMarketDate" },
  { label: "Estimated Ship Date", id: "estShipDate" },
  { label: "Ship Date", id: "shipDate" },
  { label: "Status", id: "status" },
  { label: "Compliance Status", id: "complianceStatus" },
  { label: "Supplier", id: "suppler" },
  { label: "Order Number", id: "orderNumber" },
  { label: "Invoice Number", id: "invoiceNumber" },
  { label: "Internal Order", id: "internalOrder" },
  { label: "Anaplan Codes", id: "anaplanSubProgramCodes" },
];

export const openPurchaseOrdersHeaders = [
  { label: "Sequence #", id: "itemNumber" },
  { label: "Brand", id: "brand" },
  { label: "Item Type", id: "itemType" },
  { label: "In Market Date", id: "inMarketDate" },
  { label: "Status", id: "status" },
  { label: "Purchase Order #", id: "poId" },
  { label: "Supplier", id: "supplier" },
  { label: "Qty", id: "qty" },
  { label: "Actual Cost", id: "actCost" },
  { label: "Submitted At", id: "submittedAt" },
];

export const variantTerritoryHeaders = [
  { label: "Sequence #", id: "itemNumber" },
  { label: "Item Description", id: "description" },
  { label: "Item Type", id: "itemType" },
  { label: "Brands", id: "brands" },
  { label: "Territory", id: "territory" },
  { label: "Allocated Qty", id: "allocatedQty" },
  { label: "Ordered Qty", id: "orderedQty" },
  { label: "Pending Qty", id: "pendingQty" },
  { label: "Available", id: "available" },
  { label: "Expected Date", id: "expectedDate" },
];

export const purchaseOrderReportHeaders = [
  { label: "PO Number", id: "poId" },
  { label: "Purchaser", id: "purchaser" },
  { label: "Supplier", id: "supplier" },
  { label: "Sequence #", id: "itemNumber" },
  { label: "Brand(s)", id: "brands" },
  { label: "Item Code", id: "itemCode" },
  { label: "Item Type", id: "itemType" },
  { label: "Order Qty", id: "qty" },
  { label: "Act. Cost/Unit", id: "actualCost" },
  { label: "Extended Cost", id: "extendedCost" },
  { label: "Submitted Date", id: "submittedAt" },
  { label: "In Market Date", id: "inMarketDate" },
  { label: "Allocated", id: "allocation" },
  { label: "PO Status", id: "status" },
  { label: "Invoice Number", id: "invoiceNumber" },
  { label: "Freight Costs", id: "freightCost" },
  {
    label: "Additional Freight Invoice #",
    id: "additionalFreightInvoiceNumber",
  },
  { label: "Additional Freight Costs", id: "additionalFreightCosts" },
  { label: "Beacon", id: "includeBeacon" },
];
