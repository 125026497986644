/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";

import _ from "lodash";
import { StyledButton } from "src/components/StyledComponents";

import { VariantOption } from "@models/VariantOption";

const PreOrderProgramVariantSelection = ({
  sportVariantOptions,
  handleCreate,
}: {
  sportVariantOptions: VariantOption[];
  handleCreate: (variantOptionIds: string[]) => void;
}) => {
  const [selectedSportsOptionIds, setSelectedSportsOptionIds] = useState<
    string[]
  >([]);

  const optionsByCategory = _.groupBy(sportVariantOptions, (vo) =>
    vo.name.split(" - ").at(-1)
  );
  const selectedByCategory = _.mapValues(optionsByCategory, (options) =>
    options.filter((vo) => selectedSportsOptionIds.includes(vo.id))
  );

  const handleToggleAll = () => {
    const allOptionIds = sportVariantOptions.map((vo) => vo.id);
    const newSelectedSportsOptionIds =
      _.difference(allOptionIds, selectedSportsOptionIds).length === 0
        ? []
        : allOptionIds;
    setSelectedSportsOptionIds(newSelectedSportsOptionIds);
  };

  const handleToggleCategory = (category: string) => {
    const categoryOptionIds = optionsByCategory[category].map((vo) => vo.id);
    const allSelected = categoryOptionIds.every((id) =>
      selectedSportsOptionIds.includes(id)
    );

    const newSelectedSportsOptionIds = allSelected
      ? selectedSportsOptionIds.filter((id) => !categoryOptionIds.includes(id))
      : _.uniq([...selectedSportsOptionIds, ...categoryOptionIds]);

    setSelectedSportsOptionIds(newSelectedSportsOptionIds);
  };

  return (
    <div tw="text-sm bg-white p-6 px-8 rounded-lg space-y-6 mb-40 -mt-8">
      <div>
        <h2 tw="text-lg text-neutral-800 font-medium">Select Team Options</h2>
        <p tw="text-neutral-600 mb-2">
          This pre-order has team options. Please select the teams you would
          like to order for.
        </p>
        <button onClick={handleToggleAll} tw="text-blue-600 text-sm underline">
          {selectedSportsOptionIds.length < sportVariantOptions.length
            ? "Select All"
            : `Deselect All (${selectedSportsOptionIds.length})`}
        </button>
      </div>
      {Object.entries(optionsByCategory).map(([category, options]) => (
        <div key={category}>
          <div tw="flex gap-4 items-baseline">
            <FormControlLabel
              control={
                <Checkbox
                  color={"info"}
                  indeterminate={
                    selectedByCategory[category].length > 0 &&
                    selectedByCategory[category].length < options.length
                  }
                />
              }
              checked={selectedByCategory[category].length === options.length}
              onChange={() => handleToggleCategory(category)}
              label={<h3 tw="text-lg text-neutral-800">{category}</h3>}
            />
          </div>
          <div tw="max-w-screen-lg  columns-1 sm:columns-2 md:columns-3 ">
            {options.map((vo) => (
              <div key={vo.id} tw="w-full">
                <FormControlLabel
                  // key={vo.id}
                  control={<Checkbox />}
                  checked={selectedSportsOptionIds.includes(vo.id)}
                  onChange={() =>
                    setSelectedSportsOptionIds((o) => _.xor(o, [vo.id]))
                  }
                  label={<span tw="text-sm">{vo.name}</span>}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      <StyledButton cta onClick={() => handleCreate(selectedSportsOptionIds)}>
        Create Order Set
      </StyledButton>
    </div>
  );
};

export default PreOrderProgramVariantSelection;
