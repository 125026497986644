/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Button, CircularProgress, Typography } from "@mui/material";

import { refreshItemProgram } from "src/redux/slices/planningTool/itemProgramSlice";
import {
  handleMultiVariantUpdates,
  handleVariantUpdates,
} from "src/redux/slices/planningTool/itemProgramsHelpers";

import { VariantCategoryName } from "@models/VariantOption";

import { fetchVariantOptions } from "../helpers";
import VariantAccSection from "./VariantAccSection";

const teamCategories = ["All", "NBA", "NFL", "NHL", "NCAA"];

const VariantAccordion = ({ itemProgramId, itemProgramVariants, itemId }) => {
  const dispatch = useDispatch();

  const { handleSubmit, control, setValue, getValues } = useForm();

  const [sizes, setSizes] = useState([]);
  const [teams, setTeams] = useState([]);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [saveResult, setSaveResult] = useState(null);

  const handleSave = async (data) => {
    const getSelectedIds = (prefix) =>
      Object.keys(data)
        .filter((key) => key.startsWith(`${prefix}-`) && data[key])
        .map((key) => key.split("-")[1]);

    const selectedSizeIds = getSelectedIds("sizes");
    const selectedTeamIds = getSelectedIds("teams");

    setSaving(true);
    setLoading(true);
    setSaveResult(null);

    if (selectedSizeIds.length > 0 && selectedTeamIds.length > 0) {
      try {
        await handleMultiVariantUpdates({
          dispatch,
          itemId,
          existingVariants: itemProgramVariants,
          sizeVariantIds: selectedSizeIds,
          teamVariantIds: selectedTeamIds,
        });
        setSaveResult("Success");
      } catch (error) {
        console.error("Error updating variants:", error);
        setSaveResult("Error saving variants");
      }
    } else {
      try {
        await handleVariantUpdates({
          dispatch,
          itemId,
          variants: itemProgramVariants,
          variantOptionsIds:
            selectedSizeIds.length > 0 ? selectedSizeIds : selectedTeamIds,
        });
        setSaveResult("Success");
      } catch (error) {
        console.error("Error updating variants:", error);
        setSaveResult("Error saving variants");
      }
    }

    dispatch(refreshItemProgram(itemProgramId, () => {}));
    setLoading(false);
    setSaving(false);
  };

  useEffect(() => {
    // Load all variant options from the backend
    fetchVariantOptions().then((variantOptions) => {
      setTeams(
        variantOptions.filter(
          (option) => option.variantCategoryName === VariantCategoryName.Sports
        )
      );
      setSizes(
        variantOptions.filter(
          (option) => option.variantCategoryName === VariantCategoryName.Sizes
        )
      );
    });

    // Load the variant options for the item program.  Get only the unique ones
    const itemProgVariantOptions = itemProgramVariants
      .filter((v) => v.isActive)
      .flatMap((progVar) => progVar.selectedVariantOptions)
      .reduce((uniqueOptions, option) => {
        if (!uniqueOptions.some((opt) => opt.id === option.id)) {
          uniqueOptions.push(option);
        }
        return uniqueOptions;
      }, []);

    itemProgVariantOptions.forEach((varOpt) => {
      if (varOpt["variant-category"].name === VariantCategoryName.Sizes) {
        setValue(`sizes-${varOpt.id}`, true);
      }
      if (varOpt["variant-category"].name === VariantCategoryName.Sports) {
        setValue(`teams-${varOpt.id}`, true);
      }
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemProgramVariants]);

  return (
    <div tw="relative">
      <form onSubmit={handleSubmit(handleSave)}>
        <VariantAccSection
          varOptionName={"sizes"}
          options={sizes}
          disabled={saving || loading}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />

        <VariantAccSection
          varOptionName={"teams"}
          options={teams}
          disabled={saving || loading}
          categories={teamCategories}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
        <div tw="flex items-center mt-4">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {saving ? "Saving " : "Save "}
            {loading ? <CircularProgress tw="ml-2" size={24} /> : null}
          </Button>
          {saveResult ? (
            <Typography tw="ml-4 text-red-500">{saveResult}</Typography>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default VariantAccordion;
