/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import ControlledBrandAutocomplete from "@utils/forms/ControlledBrandAutocomplete";

import {
  useItemTypes,
  useOrderCalendarMonthOptions,
  useProgramInfo,
} from "../../../hooks/planningToolHooks";
import { createItem } from "../../../redux/slices/planningTool/itemProgramSlice";
import { SelectInput } from "../../Forms/ControlledInputs";
import { StyledButton } from "../../StyledComponents";
import ScrollIntoView from "../../Utility/ScrollIntoView";
import FormItemTypeSelector from "./FormItemTypeSelector";
import ItemForm from "./ItemForm";
import NewWrapper from "./NewWrapper";
import {
  applyDefaultsFromItemType,
  decoupleOrderType,
  fields,
  formDataFromItem,
} from "./helpers";

const ItemNew = ({ defaultValues }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    requiresOCM,
    isInventory,
    becomesOnDemand,
    isSubmitted,
    programId,
    orderCalendarMonthId,
    orderType: programOrderType,
    brandAssignmentLevel,
  } = useProgramInfo();

  const ocms = useOrderCalendarMonthOptions();
  const supplierId = useSelector((state) => state.user.supplierId);
  const itemTypes = useItemTypes((type) => type.categoryCode !== "COU");

  const programOcmInOptions = ocms.find(
    ({ id }) => id === orderCalendarMonthId
  );

  const formAttrs = useForm({
    mode: "onSubmit",
    defaultValues: formDataFromItem({}),
  });

  const { control, handleSubmit, reset } = formAttrs;
  const requiredFields = fields.default.required.concat(
    isSubmitted ? fields.submitted.required : []
  );

  const controlled = (name, rules) => ({
    name,
    control,
    rules: { ...rules, required: requiredFields.includes(name) },
  });

  const onSubmit = handleSubmit((_data) => {
    if (programId) {
      let data = decoupleOrderType(_data, programOrderType);

      if (!data.itemSubTypeId) {
        data = applyDefaultsFromItemType(data, itemTypes);
      }

      setLoading(true);
      dispatch(
        createItem(programId, data, () => {
          setOpen(false);
        })
      );
    }
  });

  useEffect(() => {
    // If default values are set,
    if (Object.keys(defaultValues).length > 0) {
      reset(formDataFromItem(defaultValues));
      setOpen(true);
    }
  }, [defaultValues, reset]);

  // Set default values
  useEffect(() => {
    if (!open) {
      reset(
        formDataFromItem({
          orderCalendarMonthId: programOcmInOptions ? orderCalendarMonthId : "",
          isInventory,
          becomesOnDemand,
          supplierId,
        })
      );
      setLoading(false);
    }
  }, [
    becomesOnDemand,
    isInventory,
    open,
    orderCalendarMonthId,
    programOcmInOptions,
    reset,
    supplierId,
  ]);

  return (
    <>
      <NewWrapper name="Item" open={open} setOpen={setOpen}>
        {Object.keys(defaultValues).length > 0 && (
          <ScrollIntoView trigger={defaultValues} />
        )}
        {!isSubmitted && (
          <div tw="flex justify-between items-center gap-4">
            <div tw="flex-grow flex space-x-4">
              <FormItemTypeSelector
                tw="flex-grow max-w-md"
                label="Item Type"
                {...controlled("itemTypeId", { required: true })}
              />
              {brandAssignmentLevel === "item" && (
                <ControlledBrandAutocomplete
                  tw="flex-grow max-w-xs"
                  limitTags={1}
                  multiple
                  label="Brands"
                  {...controlled("brands", { required: true })}
                />
              )}
              {requiresOCM && (
                <SelectInput
                  tw="flex-grow max-w-xs"
                  label="Order Window Month"
                  options={ocms}
                  {...controlled("orderCalendarMonthId", { required: true })}
                />
              )}
            </div>
            <StyledButton cta onClick={onSubmit} disabled={loading}>
              Create item
            </StyledButton>
          </div>
        )}
        {isSubmitted && (
          <>
            <ItemForm form={formAttrs} controlled={controlled} />
            <StyledButton cta tw="mt-4" onClick={onSubmit} disabled={loading}>
              Submit item
            </StyledButton>
          </>
        )}
      </NewWrapper>
    </>
  );
};

export default ItemNew;
