/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";

import { DefaultTextField } from "src/components/Forms/DefaultInputs";
import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "src/components/StyledComponents";

import { ItemSubType, ItemType } from "@models/ItemType";
import { CSVLink } from "@utils/csv";

import { useItemTypesQuery } from "../data";
import ItemSubTypeModal from "./ItemSubTypeModal";
import ItemTypeModal from "./ItemTypeModal";
import ItemTypeTable from "./ItemTypeTable";

const csvHeaders = [
  { label: "Item Type Code", key: "externalId" },
  { label: "Category Code", key: "categoryCode" },
  { label: "Description", key: "description" },
  { label: "Pack Size", key: "qtyPerPack" },
  { label: "Lead Time In Days", key: "leadTimeInDays" },
  { label: "Send to content hub", key: "sendToContentHub" },
  { label: "RTA deployment", key: "rtaDeployment" },
  { label: "Spec Type", key: "specType" },
  { label: "SubType Label", key: "label" },
  { label: "Estimated Cost", key: "estimatedCost" },
  { label: "MOQ", key: "moq" },
  { label: "Lead Time (Days)", key: "leadTimeInDays" },
  { label: "Complexity Score", key: "complexityScore" },
  { label: "POS Type", key: "posType" },
  { label: "Strategic Location", key: "strategicLocation" },
  { label: "Purpose for Item", key: "purposeForItem" },
];

const ItemTypesAdmin = () => {
  const [search, setSearch] = useState("");
  const [selectedItemType, setSelectedItemType] = useState<ItemType | null>(
    null
  );
  const [selectedItemSubType, setSelectedItemSubType] = useState<{
    itemSubType: ItemSubType | null;
    itemTypeId: string;
  } | null>(null);
  const { data = [], isLoading } = useItemTypesQuery();

  const filteredItemTypes = data.filter((it) => {
    const searchTerm = search.toLowerCase();
    return (
      it.description?.toLowerCase().includes(searchTerm) ||
      it.externalId?.toLowerCase().includes(searchTerm) ||
      it.categoryCode?.toLowerCase().includes(searchTerm)
    );
  });

  const handleSubTypeClick = (
    itemSubType: ItemSubType | null,
    itemTypeId: string
  ) => {
    setSelectedItemSubType({ itemSubType, itemTypeId });
  };

  return (
    <FixedHeightScrollLastChild tw="[--space-after-header: 60px] space-y-6">
      <div tw="flex justify-between items-center">
        <h1 tw="font-medium text-2xl mb-2">Item Types</h1>
        <CSVLink
          headers={csvHeaders}
          filename="item_types.csv"
          data={filteredItemTypes.flatMap(({ itemSubTypes, ...itemType }) =>
            itemSubTypes.map((subType) => ({
              ...itemType,
              ...subType,
            }))
          )}
        >
          <StyledButton cta>EXPORT CSV</StyledButton>
        </CSVLink>
      </div>
      <DefaultTextField
        placeholder="Search"
        tw="max-w-lg"
        autoFocus
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <ItemTypeTable
        data={filteredItemTypes}
        isLoading={isLoading}
        onItemTypeClick={(it) => setSelectedItemType(it)}
        onSubTypeClick={handleSubTypeClick}
      />
      {selectedItemType && (
        <ItemTypeModal
          handleClose={() => setSelectedItemType(null)}
          itemType={selectedItemType}
        />
      )}
      {selectedItemSubType && (
        <ItemSubTypeModal
          handleClose={() => setSelectedItemSubType(null)}
          itemSubType={selectedItemSubType.itemSubType}
          itemTypeId={selectedItemSubType.itemTypeId}
        />
      )}
    </FixedHeightScrollLastChild>
  );
};

export default ItemTypesAdmin;
