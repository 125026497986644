import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import ImageWrapper from "../Utility/ImageWrapper";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  itemGridContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
    width: "100%",
  },
  programImg: {
    width: "85px",
    height: "85px",
    marginTop: "15px",
    borderRadius: "50%",
    objectFit: "cover",
    filter: "sepia(50%)",
    transition: "all .25s ease",
    "&:hover": {
      cursor: "pointer",
      filter: "sepia(0%)",
    },
  },
  singleItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    height: "340px",
    marginBottom: "40px",
    padding: "10px",
    backgroundColor: "whitesmoke",
  },
  itemControl: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
}));

const CurrentPrograms = ({ currentPrograms }) => {
  const classes = useStyles();

  const { isActive } = useSelector((state) => state.programs);
  const currentUserRole = useSelector((state) => state.user.role);
  const filtersOpen = useSelector((state) => state.filters.filtersOpen);
  return (
    <>
      <Container style={{ textAlign: "center", maxWidth: "2000px" }}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          {currentPrograms.map((prog) => (
            <Grid
              item
              lg={filtersOpen ? 3 : 2}
              md={filtersOpen ? 4 : 3}
              sm={filtersOpen ? 6 : 4}
              xs={filtersOpen ? 12 : 6}
              key={`${prog.id}-${prog.orderMonthId}`}
            >
              <Paper className={classes.singleItem}>
                <Link
                  to={`/program/${prog.id}-${prog.orderMonthId}-${
                    isActive ? "preOrder" : "preview"
                  }#details`}
                >
                  <ImageWrapper
                    id={prog.id}
                    imgClass={classes.programImg}
                    alt={prog.name}
                    imgUrl={prog.imgUrl}
                  />
                </Link>
                <Typography className={classes.headerText}>
                  {`${prog.name}`}
                </Typography>
                <div>
                  {prog.brand.length > 1 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Tooltip
                        placement="left"
                        title={`Brand(s): ${prog.brand.join(", ")}`}
                      >
                        <span style={{ display: "flex" }}>
                          <Typography
                            className={classes.headerText}
                          >{`${prog.brand[0]}`}</Typography>
                          <MoreHorizIcon
                            fontSize="small"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                          />
                        </span>
                      </Tooltip>
                    </div>
                  ) : (
                    <Typography
                      className={classes.headerText}
                    >{`Brand: ${prog.brand[0]}`}</Typography>
                  )}
                  <Typography variant="body2" color="textSecondary">
                    {`Focus Month: ${prog.focusMonth}`}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {`Order Window: ${prog.orderWindowOpen} - ${prog.orderWindowClose}`}
                  </Typography>
                  {currentUserRole !== "field1" && (
                    <Typography className={classes.headerText}>
                      {`Forward Deployed Close Date: ${prog.orderMonth}`}
                    </Typography>
                  )}
                </div>
                {isActive &&
                  currentUserRole !== "compliance" &&
                  currentUserRole !== "read-only" && (
                    <div className={classes.itemControl}>
                      <Tooltip title="Place Pre Order">
                        <span>
                          <Button
                            component={Link}
                            to={`/orders/${prog.orderMonthId}/${prog.id}`}
                            className={classes.largeButton}
                            variant="contained"
                            color="secondary"
                            style={{ marginBottom: "10px" }}
                            startIcon={<ExitToAppIcon />}
                          >
                            ORDER
                          </Button>
                        </span>
                      </Tooltip>
                    </div>
                  )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

CurrentPrograms.propTypes = {
  currentPrograms: PropTypes.array.isRequired,
};

export default CurrentPrograms;
