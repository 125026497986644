import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosGetWithNext, axiosPost } from "../../../api/axiosCalls";
import { groupByThree } from "../../../utility/utilityFunctions";
import { setError } from "../errorSlice";
import {
  resetProgStepperValue,
  resetStepperValue,
  setIsProgStepper,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateProgStepperValue,
  updateStepperValue,
} from "../globalLoadSlice";
import { mapItemPrograms, mapItems } from "../items/maps";
import { buildOrderSetVariantPost } from "../ordering/helpers";
import { mapOrderVariants } from "../ordering/maps";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildProgramQuery } from "./helpers";
import { mapPrograms } from "./maps";

let initialState = {
  isLoading: false,
  isProgramListLoading: false,
  isItemsLoading: false,
  isFocusPDFLoading: false,
  programs: [],
  programList: [],
  currentPreOrderVariants: [],
  focusPDFItems: [],
  isPrograms: false,
  isActive: true,
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.isProgramListLoading = false;
  state.isItemsLoading = false;
  state.isNextItemsLoading = false;
  state.isFocusPDFLoading = false;
  state.error = error;
};

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setItemsIsLoading(state) {
      state.isItemsLoading = true;
    },
    setListLoading(state) {
      state.isProgramListLoading = false;
    },
    setFocusPDFLoading(state) {
      state.isFocusPDFLoading = true;
    },
    getProgramsSuccess(state, action) {
      const { programs } = action.payload;
      if (programs.length === 0) {
        state.initialLoading = false;
        state.programs = [];
        state.isPrograms = false;
      } else if (state.programs.length === 0) {
        state.programs = [...programs];
        state.initialLoading = false;
        state.isPrograms = true;
      } else {
        state.programs = [...programs];
        state.isPrograms = true;
      }
      state.isLoading = false;
      state.error = null;
    },
    getProgramItemsSuccess(state, action) {
      const { program, orderMonthId, items } = action.payload;
      let updatedPrograms = state.programs.map((prog) => {
        if (prog.id === program && prog.orderMonthId === orderMonthId) {
          return {
            ...prog,
            items: [...items],
            isItemsFetched: true,
          };
        } else {
          return prog;
        }
      });
      state.programs = updatedPrograms;
      state.isItemsLoading = false;
      state.error = null;
    },
    getFocusPDFSuccess(state, action) {
      const { items } = action.payload;
      state.focusPDFItems = [...items];
      state.isFocusPDFLoading = false;
      state.error = null;
    },
    getProgramListSuccess(state, action) {
      const { programs } = action.payload;
      state.programList = programs;
      state.isProgramListLoading = false;
      state.error = null;
    },
    setProgramStatus(state, action) {
      const { program, orderMonthId, status } = action.payload;
      let updatedPrograms = state.programs.map((prog) => {
        if (prog.id === program && prog.orderMonthId === orderMonthId) {
          return {
            ...prog,
            status: status,
          };
        } else return prog;
      });
      state.programs = updatedPrograms;
    },
    addPreOrderVariants(state, action) {
      const { variants } = action.payload;
      const newVariants = state.currentPreOrderVariants.concat(variants);
      state.currentPreOrderVariants = newVariants;
    },
    deletePreOrderVariants(state, action) {
      const { id } = action.payload;
      const variants = state.currentPreOrderVariants.filter((v) => v.id !== id);
      state.currentPreOrderVariants = variants;
    },
    setIsActive(state, action) {
      const { value } = action.payload;
      state.isActive = value;
    },
    resetPreOrderVariants(state) {
      state.currentPreOrderVariants = [];
    },
    resetFocusPDFItems(state) {
      state.focusPDFItems = [];
    },
    clearPrograms(state) {
      state.isLoading = false;
      state.isProgramListLoading = false;
      state.isItemsLoading = false;
      state.isFocusPDFLoading = false;
      state.programs = [];
      state.programList = [];
      state.currentPreOrderVariants = [];
      state.focusPDFItems = [];
      state.isPrograms = false;
      state.error = null;
    },
    clearProgramList(state) {
      state.isProgramListLoading = false;
      state.programList = [];
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const {
  setIsLoading,
  setListLoading,
  setItemsIsLoading,
  setFocusPDFLoading,
  getProgramsSuccess,
  getProgramItemsSuccess,
  getFocusPDFSuccess,
  getProgramListSuccess,
  setProgramStatus,
  addPreOrderVariants,
  deletePreOrderVariants,
  setIsActive,
  resetPreOrderVariants,
  resetFocusPDFItems,
  clearPrograms,
  clearProgramList,
  setFailure,
} = programsSlice.actions;

export default programsSlice.reducer;

export const fetchPrograms =
  (orderWindowType, territoryId, channel, active) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(
        setIsProgStepper({ stepBool: true, stepTitle: "Loading Programs" })
      );
      let programArray = [];
      let stepValue = 50;
      const queryString = buildProgramQuery({
        orderWindowType,
        territoryId,
        channel,
        isAdHoc: false,
        active,
      });
      const initialResponse = await axiosGetWithNext(queryString);
      if (initialResponse.error) throw initialResponse.error;

      const initialMappedData = mapPrograms(initialResponse.data.data, active);
      programArray = programArray.concat(initialMappedData);

      let nextLink = initialResponse.data.nextLink;
      dispatch(updateProgStepperValue({ value: stepValue }));
      if (nextLink) {
        const pageSize = initialResponse.data.data.length;
        let fetchCount =
          Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;
        stepValue = parseFloat((90 / fetchCount).toFixed(2));
        for (let i = 0; i < fetchCount; i++) {
          let nextResponse = await axiosGetWithNext(nextLink);
          if (nextResponse.error) {
            throw nextResponse.error;
          }
          nextLink = nextResponse.data.nextLink;
          let mappedData = mapPrograms(nextResponse.data.data, active);
          programArray = programArray.concat(mappedData);
          dispatch(updateProgStepperValue({ value: stepValue }));
        }
      } else {
        dispatch(updateProgStepperValue({ value: 50 }));
      }
      programArray.sort((a, b) => {
        return a.brand[0].toLowerCase() < b.brand[0].toLowerCase()
          ? -1
          : a.brand[0].toLowerCase() > b.brand[0].toLowerCase()
          ? 1
          : 0;
      });
      dispatch(resetProgStepperValue());
      dispatch(getProgramsSuccess({ programs: programArray }));
    } catch (err) {
      console.error(err);
      dispatch(resetProgStepperValue());
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Programs" }));
    }
  };

export const fetchItemPrograms = (id, orderMonthId) => async (dispatch) => {
  try {
    dispatch(setItemsIsLoading());
    dispatch(startGlobalLoad());

    const filters = Object.entries({
      "program-id": id,
      "order-calendar-month-ids": orderMonthId,
      "is-active": true,
      "rta-deployment": true,
    })
      .map(([f, v]) => `filter[${f}]=${v}`)
      .join("&");
    const queryString = `/api/item-programs?${filters}`;

    let next = "initial";
    let dataArray = [];
    while (next) {
      let response = await axiosGetWithNext(
        next === "initial" ? queryString : next
      );
      if (response.error) throw response.error;
      dataArray = dataArray.concat(response.data.data);
      next = response.data.nextLink;
    }
    const mappedData = mapItemPrograms(dataArray);
    dispatch(
      getProgramItemsSuccess({
        program: id,
        orderMonthId: orderMonthId,
        items: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    console.error(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Programs fetch items" })
    );
    dispatch(stopGlobalLoad());
  }
};

export const fetchProgramById = (id) => async (dispatch) => {
  try {
    dispatch(setListLoading());
    const url = `/api/programs/${id}`;

    const response = await axiosGet(url);
    if (response.error) throw response.error;
    const mappedPrograms = mapPrograms([response.data], true);
    dispatch(getProgramsSuccess({ programs: mappedPrograms }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Programs" }));
  }
};

export const fetchProgramList = (name) => async (dispatch) => {
  try {
    dispatch(setListLoading());
    const url = `/api/programs?filter[name]=${name}`;

    const response = await axiosGet(url);
    if (response.error) throw response.error;
    dispatch(getProgramListSuccess({ programs: response.data }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Programs" }));
  }
};

export const addVariantsToPreOrder =
  (orderId, variantArray, maybeCallback) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      let orderVariants = [];
      let orderErrors = [];
      await Promise.all(
        variantArray.map(async (id) => {
          let postData = buildOrderSetVariantPost(orderId, id);
          let response = await axiosPost("/api/order-set-variants", postData);
          if (response.error) {
            orderErrors.push(response.error);
          } else {
            orderVariants.push(
              mapOrderVariants([response.data], "order-set-variant")[0]
            );
          }
        })
      );
      if (orderErrors.length > 0) {
        throw orderErrors.join(", ");
      }
      dispatch(addPreOrderVariants({ variants: orderVariants }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(patchFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Programs" }));
    } finally {
      maybeCallback?.();
    }
  };

export const fetchFocusPDF = (programArray) => async (dispatch) => {
  try {
    dispatch(setFocusPDFLoading());
    dispatch(startGlobalLoad());
    dispatch(
      setIsStepper({ stepBool: true, stepTitle: "Generating Focus Month PDF" })
    );

    let focusItems = [];
    let stepValue = parseFloat((100 / programArray.length).toFixed(2));
    for (let i = 0; i < programArray.length; i++) {
      let queryString = `/api/items?filter[program-ids]=${programArray[i].id}&filter[order-calendar-month-ids]=${programArray[i].orderMonthId}&filter[is-active]=true`;
      let next = "initial";
      let dataArray = [];
      while (next) {
        let response = await axiosGetWithNext(
          next === "initial" ? queryString : next
        );
        if (response.error) throw response.error;
        dataArray = dataArray.concat(response.data.data);
        next = response.data.nextLink;
      }
      let mappedData = mapItems(dataArray).map((item) => ({
        ...item,
        program: [programArray[i].name],
        inMarketDate: [programArray[i].inMarketDate],
      }));
      focusItems = focusItems.concat(mappedData);
      dispatch(updateStepperValue({ value: stepValue }));
    }
    focusItems = groupByThree(focusItems);
    dispatch(stopGlobalLoad());
    dispatch(resetStepperValue());
    dispatch(getFocusPDFSuccess({ items: focusItems }));
  } catch (err) {
    dispatch(setError({ error: err.toString(), source: "Programs" }));
    dispatch(setFailure({ error: err.toString() }));
    dispatch(resetStepperValue());
  }
};
